import React, { useContext, useRef, useState, useEffect } from 'react';
import { Button, message, Menu, Dropdown, Tooltip, Select, Switch } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { ExistingPayrollTableTable } from './ExistingPayrollTableTable';
import axios from 'axios';
import { authContext } from '../../ProvideAuth';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { formatDateSpanishLong } from '../../utils';
import { FormattedUSD } from '../FormattedUSD';

import EmailReviewModal from '../EmailComposer/EmailReviewModal.js';
const { Option } = Select;

function ExistingPayrollTableContainer(props) {
  const auth = useContext(authContext);
  const [payrollDatesForSelect, setPayrollDatesForSelect] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    props.selectedPayroll ? props.selectedPayroll : null
  );
  const [payrollData, setPayrollData] = useState([]);
  const [isCondensed, setIsCondensed] = useState(
    props.isCondensed ? props.isCondensed : true
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [decimoTercerMesBoolean, setDecimoTercerMesBoolean] = useState(false);
  const [vacationBoolean, setVacationBoolean] = useState(false);
  const tableRef = useRef(null);
  const emailSentRef = useRef(false);
  const [deleting, setDeleting] = useState(false);
  const [renderAdvancePaymentOnly, setRenderAdvancePaymentOnly] =
    useState(false);
  function toggleView() {
    setIsCondensed(!isCondensed);
  }

  const handleRowClick = (record) => {
    setSelectedEmployee(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const [isEmailEditModalVisible, setIsEmailEditModalVisible] = useState(false);
  const [emailData, setEmailData] = useState({});

  let accountingClientLogoSrc =
    'accounting_client_logo_' + props.selectedAccountingClientId + '.png';

  const exportPDF = async (
    file_name = 'download_hero.pdf',
    outputOnlyBlob = false
  ) => {
    const doc = new jsPDF('landscape', 'pt', 'A4');
    doc.setFontSize(18);
    const title = `Planilla del ${formatDateSpanishLong(
      payrollData[0].payable_date
    )} ${decimoTercerMesBoolean ? ' - Décimo Tercer Mes' : ''} ${
      vacationBoolean ? ' - Vacaciones' : ''
    }`;
    doc.text(title, 40, 70);

    const loadImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => {
          console.warn(`Failed to load image: ${src}`);
          resolve(null); // Resolve with null if image fails to load
        };
        img.src = src;
      });
    };

    const logo1 = await loadImage(accountingClientLogoSrc);
    const logo2 = await loadImage('heroLogoSmall.png');

    const drawLogos = async () => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const logoHeight = 50;
      let xOffset = pageWidth - 30;
      const yOffset = 20;

      if (logo2) {
        const aspectRatio2 = logo2.width / logo2.height;
        const logoWidth2 = logoHeight * aspectRatio2;
        xOffset -= logoWidth2;
        doc.addImage(
          logo2.src,
          'PNG',
          xOffset,
          yOffset,
          logoWidth2,
          logoHeight
        );
      }

      if (logo1) {
        const aspectRatio1 = logo1.width / logo1.height;
        const logoWidth1 = logoHeight * aspectRatio1;
        xOffset -= logoWidth1 + 10;
        doc.addImage(
          logo1.src,
          'PNG',
          xOffset,
          yOffset,
          logoWidth1,
          logoHeight
        );
      }

      const tableElement = tableRef.current; // use the ref here
      if (!tableElement) {
        console.error('Table element is not available');
        return;
      }

      const tableRows = [];
      const tableHeaders = Array.from(
        tableElement.querySelectorAll('thead tr th')
      ).map((th) => th.innerText);

      tableElement.querySelectorAll('tbody tr').forEach((row) => {
        const rowData = Array.from(row.querySelectorAll('td')).map(
          (td) => td.innerText
        );
        tableRows.push(rowData);
      });

      // Calculate the totals for each column
      const totals = tableHeaders.map((header, index) => {
        if (
          header !== 'Colaborador' &&
          header !== 'Fecha' &&
          index > 0 &&
          header !== 'Notas'
        ) {
          const total = tableRows
            .reduce((acc, row) => {
              const value = row[index].replace(/[^\d.-]/g, ''); // Remove non-numeric characters
              return acc + (parseFloat(value) || 0);
            }, 0)
            .toFixed(2);
          return `$${total}`;
        }
        return '';
      });

      // Add the totals row to the table data
      tableRows.push(totals);

      const tableStyles = {
        head: [tableHeaders],
        theme: 'plain',
        body: tableRows,
        startY: 100,
        styles: {
          fontSize: 10,
          cellPadding: { top: 5, right: 3, bottom: 5, left: 3 },
          overflow: 'linebreak',
          valign: 'middle',
          halign: 'center',
          fillColor: [255, 255, 255],
        },
        headStyles: {
          fillColor: [116, 116, 199],
          textColor: [255, 255, 255],
          fontSize: 8,
          halign: 'center',
        },
        bodyStyles: {
          halign: 'center',
          cellPadding: { top: 12, bottom: 12 },
        },
        columnStyles: {
          [tableHeaders.indexOf('Total de Ingresos')]: {
            textColor: [0, 128, 0],
            fontStyle: 'bold',
          },
          [tableHeaders.indexOf('Total de Deducciones')]: {
            textColor: [255, 0, 0],
            fontStyle: 'bold',
          },
          [tableHeaders.indexOf('Salario Neto a Recibir')]: {
            textColor: [65, 130, 239],
            fontStyle: 'bold',
          },
        },
        margin: { top: 150, left: 20, right: 20 },
      };

      doc.autoTable(tableStyles);

      if (outputOnlyBlob) {
        const pdfBlob = await doc.output('blob'); // Await the Promise here
        return pdfBlob;
      } else {
        doc.save(file_name);
      }
    };

    try {
      if (outputOnlyBlob) {
        const pdfBlob = await drawLogos();
        return pdfBlob;
      } else {
        await drawLogos();
      }
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      throw error;
    }
  };

  const getSlipPDF = async (employee, outputOnlyBlob = false) => {
    const doc = new jsPDF('landscape', 'pt', 'A4');
    doc.setFontSize(18);
    const title = `Comprobante de Planilla: ${formatDateSpanishLong(
      employee.payable_date
    )} ${decimoTercerMesBoolean ? '- Décimo Tercer Mes' : ''}  ${
      vacationBoolean ? '- Vacaciones' : ''
    }`;
    doc.text(title, 40, 70);

    const loadImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => {
          console.warn(`Failed to load image: ${src}`);
          resolve(null); // Resolve with null if image fails to load
        };
        img.src = src;
      });
    };

    const logo1 = await loadImage(
      `accounting_client_logo_${employee.accounting_clients_id}.png`
    );
    const logo2 = await loadImage('heroLogoSmall.png');

    const drawLogos = async () => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const logoHeight = 50;
      let xOffset = pageWidth - 30;
      const yOffset = 20;

      if (logo2) {
        const aspectRatio2 = logo2.width / logo2.height;
        const logoWidth2 = logoHeight * aspectRatio2;
        xOffset -= logoWidth2;
        doc.addImage(
          logo2.src,
          'PNG',
          xOffset,
          yOffset,
          logoWidth2,
          logoHeight
        );
      }

      if (logo1) {
        const aspectRatio1 = logo1.width / logo1.height;
        const logoWidth1 = logoHeight * aspectRatio1;
        xOffset -= logoWidth1 + 10;
        doc.addImage(
          logo1.src,
          'PNG',
          xOffset,
          yOffset,
          logoWidth1,
          logoHeight
        );
      }

      // Add employee name in bold
      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      doc.text(`${employee.name}`, 40, 110);

      // Add employee government ID
      doc.setFont('Helvetica', 'normal');
      doc.text(`${employee.gov_id || ''}`, 40, 130);

      doc.setFontSize(12);
      const notesY = 160;
      doc.text(`NOTAS: ${employee.notes || ''}`, 40, notesY);

      const startY = 200;

      const tableWidth = (pageWidth - 80) / 3 - 20;
      const tableMargin = 10;
      const symbolOffset = 15;

      const formatCurrency = (amount) => {
        return `$${parseFloat(amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      };

      const drawTableWithBorder = (
        x,
        y,
        head,
        body,
        headStyles,
        symbol,
        lastRowColor
      ) => {
        doc.autoTable({
          head: [head],
          theme: 'plain',
          body: body,
          startY: y + tableMargin,
          tableWidth: tableWidth - tableMargin * 2,
          margin: { left: x + tableMargin },
          styles: {
            cellPadding: 3,
            fontSize: 10,
            fillColor: [255, 255, 255],
          },
          headStyles: headStyles,
          bodyStyles: { fillColor: [255, 255, 255] },
          didParseCell: function (data) {
            if (data.section === 'body' && data.row.index === body.length - 1) {
              data.cell.styles.textColor = lastRowColor;
              data.cell.styles.fontStyle = 'bold';
            }
          },
        });

        const tableHeight = doc.lastAutoTable.finalY - y;
        doc.setLineWidth(1.2);
        doc.roundedRect(x, y, tableWidth, tableHeight + tableMargin * 2, 5, 5);

        if (symbol) {
          const symbolX = x + tableWidth + symbolOffset;
          const symbolY = y + (tableHeight + tableMargin * 2) / 2;
          doc.text(symbol, symbolX, symbolY, {
            align: 'center',
            baseline: 'middle',
          });
        }
      };

      drawTableWithBorder(
        40,
        startY,
        ['Ingresos', 'Monto'],
        [
          ['Salario Regular', formatCurrency(employee.biweekly_salary)],
          ...(employee.benefit_production + employee.benefit_fuel > 0
            ? [
                [
                  'Primas',
                  formatCurrency(
                    employee.benefit_production + employee.benefit_fuel
                  ),
                ],
              ]
            : []),
          ...(employee.overtime > 0
            ? [['Tiempo Extra', formatCurrency(employee.overtime)]]
            : []),
          ...(employee.benefit_representation_expenses > 0
            ? [
                [
                  'Gastos de Representación',
                  formatCurrency(employee.benefit_representation_expenses),
                ],
              ]
            : []),
          ['Total de Ingresos', formatCurrency(employee.payroll_gross)],
        ],
        { fillColor: [82, 164, 65], textColor: [255, 255, 255] },
        '-',
        [82, 164, 65]
      );

      drawTableWithBorder(
        40 + tableWidth + symbolOffset * 2,
        startY,
        ['Deducciones', 'Monto'],
        [
          ['Seguro Social 9.75%', formatCurrency(employee.deduction_ss)],
          ['Seguro Educativo 1.25%', formatCurrency(employee.deduction_se)],
          ['ISR', formatCurrency(employee.deduction_isr)],
          ...(employee.deduction_attendance > 0
            ? [['Ausencias', formatCurrency(employee.deduction_attendance)]]
            : []),
          ...(employee.deduction_direct_discount > 0
            ? [
                [
                  'Descuento Directo',
                  formatCurrency(employee.deduction_direct_discount),
                ],
              ]
            : []),
          ...(employee.advance_deductions > 0
            ? [
                [
                  'Descuento de Adelantos',
                  formatCurrency(employee.advance_deductions),
                ],
              ]
            : []),
          ['Total de Deducciones', formatCurrency(employee.deduction_total)],
        ],
        { fillColor: [231, 76, 60], textColor: [255, 255, 255] },
        '=',
        [231, 76, 60]
      );

      drawTableWithBorder(
        40 + tableWidth * 2 + symbolOffset * 4,
        startY,
        ['Salario Neto a Recibir', 'Monto'],
        [['', formatCurrency(employee.payroll_net)]],
        { fillColor: [81, 128, 232], textColor: [255, 255, 255] },
        null,
        [81, 128, 232]
      );

      const firmaY = doc.internal.pageSize.getHeight() - 200;
      doc.setFontSize(12);
      doc.text(
        'Firma Recibido: __________________________________',
        40,
        firmaY
      );

      if (outputOnlyBlob) {
        const pdfBlob = await doc.output('blob'); // Await the Promise here
        return pdfBlob;
      } else {
        const fileName = `${employee.payable_date}_comprobante_${employee.name}.pdf`;
        doc.save(fileName);
      }
    };

    try {
      if (outputOnlyBlob) {
        const pdfBlob = await drawLogos();
        return pdfBlob;
      } else {
        await drawLogos();
      }
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      throw error;
    }
  };

  const exportZoho = (approved_payroll_id) => {
    const data = { payrollId: approved_payroll_id };

    axios({
      method: 'post',
      url: props.API_domain + 'getCSVwithPayrollDetails',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        const csvContent = response.data;
        const csvBlob = new Blob(['\ufeff', csvContent], {
          type: 'text/csv;charset=utf-8;',
        });
        const downloadUrl = window.URL.createObjectURL(csvBlob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = `${approved_payroll_id}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const exportSipe = (approved_payroll_id) => {
    const data = { payrollId: approved_payroll_id };

    axios({
      method: 'post',
      url: props.API_domain + 'getSIPEUploader',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        const csvContent = response.data;
        const csvBlob = new Blob(['\ufeff', csvContent], {
          type: 'text/csv;charset=utf-8;',
        });
        const downloadUrl = window.URL.createObjectURL(csvBlob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = `${approved_payroll_id}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const sendEmails = async () => {
    //if (emailSentRef.current) return;
    //emailSentRef.current = true;

    if (!payrollDatesForSelect.length || !selectedDate || !payrollData.length) {
      console.error('Required data not available');
      return;
    }
    const get_accounting_client_data_response = await axios.get(
      `${props.API_domain}getDataForAccountingClient/${props.selectedAccountingClientId}`,
      {
        auth: {
          username: auth.email,
          password: auth.token,
        },
      }
    );

    const { accounting_client_name, emails } =
      get_accounting_client_data_response.data;

    const payrollPDF = await exportPDF(
      payrollData[0].payable_date + '_planilla.pdf',
      true // Output only the PDF blob
    );

    // Generate all slip PDFs
    const slipPDFsPromises = payrollData.map((employee) =>
      getSlipPDF(employee, true)
    );
    const slipPDFs = await Promise.all(slipPDFsPromises);

    // Send all PDFs to the backend
    // sendPDFsToBackend(
    //   payrollPDF,
    //   slipPDFs,
    //   payrollData[0].payable_date,
    //   payrollData
    // );
    // Set the email data for review
    setEmailData({
      to: emails.join(','),
      subject: `Planilla del ${payrollData[0].payable_date} - ${accounting_client_name}`,
      body: 'Adjunto podrán encontrar la planilla y sus comprobantes.',
      attachments: [
        {
          name: `${payrollData[0].payable_date}_planilla.pdf`,
          content: payrollPDF,
        },
        ...slipPDFs.map((pdf, index) => ({
          name: `${payrollData[index].payable_date}_comprobante_${payrollData[index].name}.pdf`,
          content: pdf,
        })),
      ],
    });
    // Show the modal for email review
    setIsEmailEditModalVisible(true);
  };

  const handleSend = (data) => {
    // Function to handle sending the email
    // use data.to, data.subject, data.body, data.attachments to send the email
    console.log('Email Data to send:', data);
    setIsModalVisible(false);

    // Proceed with sending the email
  };

  useEffect(() => {
    if (
      props.automaticallySendEmailBoolean &&
      payrollDatesForSelect.length &&
      payrollData.length
    ) {
      sendEmails();
    } else if (props.sendApprovedPayroll) {
      if (payrollDatesForSelect.length && payrollData.length) {
        sendEmails();
        props.setSendApprovedPayroll(false);
      }
    }
  }, [
    props.automaticallySendEmailBoolean,
    payrollDatesForSelect,
    payrollData,
    props.sendApprovedPayroll,
  ]);

  useEffect(() => {
    const fetchPayrollDatesForSelect = async () => {
      try {
        const response = await axios.post(
          `${props.API_domain}getOldPayrollDates`,
          {
            selectedAccountingClientId: props.selectedAccountingClientId,
          },
          { auth: { username: auth.email, password: auth.token } }
        );
        if (response.data && Array.isArray(response.data)) {
          setPayrollDatesForSelect(response.data);
          if (!selectedDate) {
            setSelectedDate(response.data[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching payroll dates:', error);
        message.error('Failed to load payroll dates.');
      }
    };

    fetchPayrollDatesForSelect();
  }, []);

  useEffect(() => {
    setDecimoTercerMesBoolean(false);
    setVacationBoolean(false);
    setRenderAdvancePaymentOnly(false);
    const fetchPayrollData = async () => {
      if (!selectedDate) return;
      try {
        const response = await axios.post(
          `${props.API_domain}getPayroll`,
          {
            accountingClientsId: props.selectedAccountingClientId,
            approvedPayrollId: selectedDate.approved_payroll_id,
          },
          { auth: { username: auth.email, password: auth.token } }
        );
        if (response.data && Array.isArray(response.data.payroll)) {
          if (response.data.payroll[0].comments === 'DECIMO') {
            setDecimoTercerMesBoolean(true);
          }
          if (response.data.payroll[0].comments === 'VACACIONES') {
            setVacationBoolean(true);
          }
          response.data.payroll.forEach((record, index) => {
            record.index = index + 1;
          });
          setPayrollData(response.data.payroll);
          // check if payroll is simply an advance_payment
          if (
            response.data.payroll.length === 1 &&
            response.data.payroll[0].comments === 'ADELANTO'
          ) {
            setRenderAdvancePaymentOnly(true);
          }
          if (response.data.payroll[0].comments === 'VACACIONES') {
          }
        }
      } catch (error) {
        console.error('Error fetching payroll data:', error);
        message.error('Failed to load payroll data.');
      }
    };

    fetchPayrollData();
  }, [selectedDate]);

  const handleDateChange = (value) => {
    const date = payrollDatesForSelect.find(
      (d) => d.approved_payroll_id === value
    );
    setSelectedDate(date);
  };

  const menu = (
    <Menu>
      <Menu.Item key='1'>
        <Button
          type='link'
          onClick={() =>
            exportPDF(payrollData[0].payable_date + '_planilla.pdf', false)
          }
          disabled={!selectedDate}
        >
          Exportar a <b> PDF</b>
        </Button>
      </Menu.Item>
      <Menu.Item key='2'>
        <Button
          type='link'
          onClick={() => exportZoho(selectedDate.approved_payroll_id)}
          disabled={!selectedDate}
        >
          Exportar a <b> ZOHO</b>
        </Button>
      </Menu.Item>
      <Menu.Item key='3'>
        <Button
          type='link'
          onClick={() => exportSipe(selectedDate.approved_payroll_id)}
          disabled={!selectedDate}
        >
          Exportar a <b> SIPE</b>
        </Button>
      </Menu.Item>
      <Menu.Item key='4'>
        <Button type='link' onClick={sendEmails} disabled={!selectedDate}>
          Mandar <b> Correo</b>
        </Button>
      </Menu.Item>
      <Menu.Item key='5'>
        <Button
          type='link'
          loading={deleting}
          onClick={() => {
            setDeleting(true);
            axios({
              method: 'post',
              url: props.API_domain + 'deleteApprovedPayroll',
              data: {
                approved_payroll_id: selectedDate.approved_payroll_id,
              },
              auth: {
                username: auth.email,
                password: auth.token,
              },
            })
              .then((response) => {
                setDeleting(false);
                message.success('Planilla borrada exitosamente');
                setSelectedDate(null);
                props.onCancel();
              })
              .catch((error) => {
                console.error('Error:', error);
                message.error('Failed to delete payroll');
              });
          }}
          disabled={!selectedDate}
          style={{ color: 'red', fontWeight: 'bold' }}
        >
          Borrar Planilla
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div id='SelectDiv' style={{ flex: '0 1 auto' }}>
          <Select
            style={{ width: 320, marginBottom: 20 }}
            placeholder='Escoge una planilla'
            onChange={handleDateChange}
            value={selectedDate ? selectedDate.approved_payroll_id : undefined}
          >
            {payrollDatesForSelect
              .sort(
                (a, b) => new Date(b.payable_date) - new Date(a.payable_date)
              )
              .map((date) => (
                <Option
                  key={date.approved_payroll_id}
                  value={date.approved_payroll_id}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      {date.payable_date}
                      {date.comments ? ` - ${date.comments}` : ''}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <b>
                        <FormattedUSD total={date.total_payroll_net} />
                      </b>
                      <Tooltip
                        title={
                          <ol style={{ marginLeft: 0, paddingLeft: 16 }}>
                            {date.employee_names
                              .split(',')
                              .map((name, index) => (
                                <li key={index}>{name.trim()}</li>
                              ))}
                          </ol>
                        }
                        placement='topLeft'
                      >
                        <span style={{ color: 'gray', marginLeft: 10 }}>
                          {date.employee_names.split(',').length}
                          <UserOutlined style={{ marginLeft: 4 }} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </Option>
              ))}
          </Select>
        </div>
        <div>
          <h2 style={{ paddingLeft: 20, fontSize: 30 }}>
            {selectedDate ? (
              <>
                {renderAdvancePaymentOnly ? 'Adelanto' : 'Planilla'} del{' '}
                <b>{formatDateSpanishLong(selectedDate.payable_date)}</b>
                {decimoTercerMesBoolean ? ' - Décimo Tercer Mes' : ''}
                {vacationBoolean ? ' - Vacaciones' : ''}
              </>
            ) : (
              <>Planilla</>
            )}
          </h2>
        </div>
        {!renderAdvancePaymentOnly ? (
          <div id='ActionsDiv' style={{ flex: '1 1 auto', textAlign: 'right' }}>
            <span style={{ marginLeft: 8, marginRight: 8 }}>Vista simple:</span>
            <Switch checked={isCondensed} onChange={toggleView} />
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement='bottomRight'
            >
              <Button type='primary' style={{ marginLeft: 10 }}>
                Acciones <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        ) : (
          <div id='ActionsDiv' style={{ flex: '1 1 auto', textAlign: 'right' }}>
            {/* intentionally empty to not screw up rendering */}
          </div>
        )}
      </div>

      {renderAdvancePaymentOnly ? (
        <div
          style={{
            height: '320px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>
            Adelanto de{' '}
            <span style={{ fontWeight: 'bold' }}>
              <FormattedUSD total={payrollData[0].advance_payments} />
            </span>{' '}
            a <span style={{ fontWeight: 'bold' }}>{payrollData[0].name}</span>
          </h2>
        </div>
      ) : (
        <>
          <ExistingPayrollTableTable
            ref={tableRef} // this is for exportPDF to work
            payrollData={payrollData}
            isCondensed={isCondensed}
            handleRowClick={handleRowClick}
            isModalVisible={isModalVisible}
            handleModalClose={handleModalClose}
            selectedEmployee={selectedEmployee}
            getSlipPDF={getSlipPDF}
            decimoTercerMesBoolean={decimoTercerMesBoolean}
          />
          <EmailReviewModal
            visible={isEmailEditModalVisible}
            onCancel={() => setIsEmailEditModalVisible(false)}
            onSend={handleSend}
            initialValues={emailData}
            API_domain={props.API_domain}
          />
        </>
      )}
    </>
  );
}

export { ExistingPayrollTableContainer };
