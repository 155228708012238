import React, { useContext, useState } from 'react';
import { Affix, Col, Layout, Row, Button } from 'antd';
import styled from 'styled-components/macro';
import { BankView } from './BankView.js';
import { ExpensesView } from './ExpensesView.js';
import { InvuTable } from './InvuTable.js';
import { FETable } from './FETable.js';
import { authContext } from '../ProvideAuth.js';

const { Content } = Layout;

function PaperMovementsView(props) {
  const [activeComponent, setActiveComponent] = useState('expenses');
  const auth = useContext(authContext);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'expenses':
        return (
          <ExpensesView
            API_domain={props.API_domain}
            team={props.team}
            onTeamSelect={props.onTeamSelect}
            mobile={props.mobile}
          />
        );
      case 'invu':
        return (
          <InvuTable
            API_domain={props.API_domain}
            team={props.team}
            mobile={props.mobile}
          />
        );
      case 'feRecibidas':
        return (
          <FETable
            API_domain={props.API_domain}
            team={props.team}
            mobile={props.mobile}
            type='fe_recibidas'
          />
        );
      case 'feEmitidas':
        return (
          <FETable
            API_domain={props.API_domain}
            team={props.team}
            mobile={props.mobile}
            type='fe_emitidas'
          />
        );
      default:
        return <BankView API_domain={props.API_domain} />;
    }
  };

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        // borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        // borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('expenses')}
                isActive={activeComponent === 'expenses'}
              >
                Bot de WhatsApp
              </NavButton>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('feRecibidas')}
                isActive={activeComponent === 'feRecibidas'}
              >
                FE Recibidas
              </NavButton>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('feEmitidas')}
                isActive={activeComponent === 'feEmitidas'}
              >
                FE Emitidas
              </NavButton>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('invu')}
                isActive={activeComponent === 'invu'}
                disabled={auth.adminEmail ? false : true}
              >
                Invu
              </NavButton>
              {/* Add more buttons for additional components */}
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {/* You can add other elements or controls here */}
            </Col>
          </Row>
        </AffixDiv>
      </Affix>

      <div>{renderComponent()}</div>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
  // border-left: solid rgb(235, 235, 235) 1px;
`;

// Styled button component to reflect the active state
const NavButton = styled(Button)`
  color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
  font-weight: ${({ isActive }) => (isActive ? '600' : 'normal')};
  font-size: 16px;
  background: none; /* Ensure no background is set by default */
  border: none; /* Remove border if not needed */

  /* Override hover state */
  &:hover {
    color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
    background: none; /* Prevent background color change on hover */
    border: none;
    box-shadow: none;
  }

  /* Override focus state */
  &:focus {
    color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
    background: none; /* Prevent background color change on focus */
    border: none;
    box-shadow: none;
    outline: none; /* Remove default focus outline */
  }

  /* Override active state */
  &:active {
    color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
    background: none; /* Prevent background color change on active */
    border: none;
    box-shadow: none;
  }
`;

export { PaperMovementsView };
