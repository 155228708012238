import { useContext, useState, useEffect } from 'react';
import {
  Button,
  Input,
  Layout,
  Table,
  message,
  Spin,
  Modal,
  Space,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'; // Import DownloadOutlined
import styled from 'styled-components/macro';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import moment from 'moment'; // Import moment for date formatting
import { convertToIntDateFormat } from '../utils.js';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { get_moments_from_month_name } from '../utils.js';
import * as XLSX from 'xlsx'; // Import XLSX for CSV export

const { Content } = Layout;

function FETable(props) {
  const [fes, setFes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal state
  const [pdfUrl, setPdfUrl] = useState(''); // PDF URL state
  const [fetchingPdf, setFetchingPdf] = useState(false); // To show spinner while PDF is fetching

  const auth = useContext(authContext);

  useEffect(() => {
    const fetchFEs = () => {
      setLoading(true);

      axios({
        method: 'post',
        url: props.API_domain + 'getFEs',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          docs_type: props.type,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setFes(response.data);
            console.log('Fetched fes:', response.data);
          } else {
            message.error('Failed to fetch fes');
          }
        })
        .catch((error) => {
          console.error('Error fetching fes:', error);
          message.error('Error consiguiendo las facturas electrónicas');
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchFEs();
  }, [props.API_domain, props.type]);

  // Function to handle when a CUFE is clicked
  const handleCufeClick = (cufe) => {
    setIsModalVisible(true); // Open the modal
    setFetchingPdf(true); // Show loading indicator

    // Fetch the PDF link from the backend using CUFE
    axios({
      method: 'post',
      url: props.API_domain + 'getFEpdf', // Update the URL to match your endpoint
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        cufe, // Send the clicked CUFE to the backend to fetch the PDF link
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setPdfUrl(response.data.fe_pdf_url); // Assuming response.data.fe_pdf_url contains the PDF URL
        } else {
          message.error('Failed to fetch PDF link');
        }
      })
      .catch((error) => {
        console.error('Error fetching PDF link:', error);
        message.error('Error consiguiendo el enlace del PDF.');
      })
      .finally(() => {
        setFetchingPdf(false); // Stop showing spinner after fetch
      });
  };

  const columns = [
    {
      title: 'CUFE',
      dataIndex: 'cufe',
      key: 'cufe',
      // width: 300,
      render: (text) => (
        <Button type='link' onClick={() => handleCufeClick(text)}>
          {text}
        </Button>
      ), // Make the CUFE clickable and trigger the modal
    },
    ...(props.type === 'fe_recibidas'
      ? [
          {
            title: 'Emisor',
            dataIndex: ['emissor_name', 'emissor_id'], // Combine both name and id for search filtering
            key: 'emissor',
            width: 400,
            render: (text, record) => (
              <div>
                <div>{record.emissor_name}</div>
                <div style={{ fontSize: '12px', color: '#888' }}>
                  {record.emissor_id}
                </div>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Buscar emisor o RUC`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={confirm}
                  style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type='primary'
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Buscar
                  </Button>
                  <Button
                    onClick={clearFilters}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            onFilter: (value, record) => {
              const searchText = value.toLowerCase();
              return (
                record.emissor_name.toLowerCase().includes(searchText) ||
                record.emissor_id.toString().includes(searchText)
              );
            },
          },
        ]
      : []),
    ...(props.type === 'fe_emitidas'
      ? [
          {
            title: 'Receptor',
            dataIndex: ['receiver_name', 'receiver_id'], // Combine both name and id for search filtering
            key: 'receiver',
            width: 360,
            render: (text, record) => (
              <div>
                <div>{record.receiver_name}</div>
                <div style={{ fontSize: '12px', color: '#888' }}>
                  {record.receiver_id !== 'missing' ? record.receiver_id : ''}
                </div>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Buscar receptor o RUC`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={confirm}
                  style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type='primary'
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Buscar
                  </Button>
                  <Button
                    onClick={clearFilters}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            onFilter: (value, record) => {
              const searchText = value.toLowerCase();
              return (
                record.receiver_name.toLowerCase().includes(searchText) ||
                record.receiver_id.toString().includes(searchText)
              );
            },
          },
        ]
      : []),
    {
      title: 'Fecha de Emisión',
      dataIndex: 'emission_date',
      key: 'emission_date',
      width: 240, // Adjust width as needed
      sorter: (a, b) =>
        moment(a.emission_date).unix() - moment(b.emission_date).unix(),
      render: (text) =>
        convertToIntDateFormat(moment(text).format('YYYY-MM-DD')),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const emissionDate = moment(record.emission_date);

        if (Array.isArray(value) && value.length === 2) {
          const [start, end] = value;
          return emissionDate.isBetween(start, end, 'day', '[]');
        } else if (typeof value === 'string') {
          const filterDates = get_moments_from_month_name(value);
          return emissionDate.isBetween(
            filterDates[0],
            filterDates[1],
            'day',
            '[]'
          );
        }

        return false;
      },
    },
    {
      title: 'ITBMS',
      dataIndex: 'itbms',
      key: 'itbms',
      render: (value) =>
        value !== undefined ? `$${value.toFixed(2)}` : '$0.00',
      sorter: (a, b) => (a.itbms || 0) - (b.itbms || 0),
      align: 'right',
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => (
        <div style={{ textAlign: 'right' }}>
          {value !== undefined ? `$${value.toFixed(2)}` : '$0.00'}
        </div>
      ),
      sorter: (a, b) => (a.amount || 0) - (b.amount || 0),
      align: 'right',
    },
  ];

  // Function to export table data to CSV
  const exportToCSV = () => {
    if (!fes || fes.length === 0) {
      message.warning('No hay datos para exportar.');
      return;
    }

    // Define headers based on columns
    const headers = ['CUFE'];

    if (props.type === 'fe_recibidas') {
      headers.push('Emisor Nombre', 'Emisor RUC');
    } else if (props.type === 'fe_emitidas') {
      headers.push('Receptor Nombre', 'Receptor RUC');
    }

    headers.push('Fecha de Emisión', 'ITBMS', 'Total');

    // Map data to rows
    const data = fes.map((item) => {
      const row = [item.cufe];

      if (props.type === 'fe_recibidas') {
        row.push(item.emissor_name, item.emissor_id);
      } else if (props.type === 'fe_emitidas') {
        row.push(
          item.receiver_name,
          item.receiver_id !== 'missing' ? item.receiver_id : ''
        );
      }

      row.push(moment(item.emission_date).format('YYYY-MM-DD'));
      row.push(item.itbms !== undefined ? `${item.itbms.toFixed(2)}` : '0.00');
      row.push(
        item.amount !== undefined ? `${item.amount.toFixed(2)}` : '0.00'
      );

      return row;
    });

    // Combine headers and data
    const csvData = [headers, ...data];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(csvData);

    // Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'FEs');

    // Generate a binary string representation of the workbook
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });

    // Create a link to download the Blob
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'FEs.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Content
      style={{
        overflow: 'initial',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv style={{ maxWidth: '1400px', margin: 'auto' }}>
        <div style={{ textAlign: 'right' }}>
          <Button
            type='secondary'
            icon={<DownloadOutlined />}
            onClick={exportToCSV}
          >
            Excel
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={fes}
          rowKey='id'
          loading={loading}
          pagination={{ pageSize: 100 }}
          style={{ margin: 'auto' }}
        />
      </AffixDiv>

      {/* Modal for displaying the PDF */}
      <Modal
        title='Factura Electrónica PDF'
        visible={isModalVisible}
        footer={null} // No footer buttons
        onCancel={() => setIsModalVisible(false)} // Close the modal
        width={800} // Width of the modal
      >
        {fetchingPdf ? (
          <Spin tip='Cargando PDF...' /> // Show spinner while loading
        ) : pdfUrl !== '' ? (
          <iframe
            src={pdfUrl}
            width='100%'
            height='600px'
            title='Factura PDF'
            frameBorder='0'
          />
        ) : (
          <p>No se encontró el PDF.</p>
        )}
      </Modal>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

export { FETable };
