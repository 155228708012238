import { useContext, useState, useEffect } from 'react';
import { Button, Layout, Table, message, Modal, Spin, DatePicker } from 'antd';
import styled from 'styled-components/macro';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import moment from 'moment'; // Import moment for date formatting

const { Content } = Layout;

function InvuTable(props) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const auth = useContext(authContext);
  const [pdfContent, setPdfContent] = useState('');
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null); // To store the selected date

  useEffect(() => {
    const fetchOrders = () => {
      setLoading(true);

      axios({
        method: 'post',
        url: props.API_domain + 'getInvuOrdersForAccountingClient',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {},
      })
        .then((response) => {
          if (response.status === 200 && response.data.invu_orders) {
            setOrders(response.data.invu_orders);
            setFilteredOrders(response.data.invu_orders); // Set filtered orders initially
            console.log('Orders:', response.data.invu_orders);
          } else {
            message.error('Failed to fetch orders');
          }
        })
        .catch((error) => {
          console.error('Error fetching orders:', error);
          message.error('An error occurred while fetching orders');
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchOrders();
  }, [props.API_domain]);

  const handleOpenModal = (numero_factura) => {
    setLoadingPdf(true);
    setModalVisible(true);

    axios({
      method: 'post',
      url: props.API_domain + 'getFacturaXML',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        numero_factura: numero_factura,
      },
    })
      .then((response) => {
        if (response.status === 200 && response.data.xml) {
          setPdfContent(response.data.xml);
        } else {
          message.error('Failed to retrieve XML data');
          setPdfContent('Error retrieving data');
        }
      })
      .catch((error) => {
        console.error('Error fetching XML:', error);
        message.error('An error occurred while fetching XML');
        setPdfContent('Error retrieving data');
      })
      .finally(() => {
        setLoadingPdf(false);
      });
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setPdfContent('');
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      // Filter orders based on the selected date
      const filtered = orders.filter((order) =>
        moment(order.fecha_cierre_date).isSame(date, 'day')
      );
      setFilteredOrders(filtered);
    } else {
      // If no date is selected, show all orders
      setFilteredOrders(orders);
    }
  };

  const columns = [
    {
      title: 'Fecha de Cierre',
      dataIndex: 'fecha_cierre_date',
      key: 'fecha_cierre_date',
      sorter: (a, b) =>
        moment(a.fecha_cierre_date).unix() - moment(b.fecha_cierre_date).unix(),
      render: (text) => moment(text).format('YYYY-MM-DD'), // Format date to only show the date
      filterDropdown: ({ confirm }) => (
        <DatePicker
          onChange={(date) => {
            handleDateChange(date);
            confirm(); // Close the dropdown after selection
          }}
          value={selectedDate}
          allowClear
          autoFocus // Automatically focuses the DatePicker when opened
          open // Keeps the DatePicker open immediately without extra step
          onBlur={confirm} // Ensure dropdown closes if the user clicks away
          getPopupContainer={(trigger) => trigger.parentElement} // To prevent position issues
        />
      ),
      onFilter: (value, record) =>
        moment(record.fecha_cierre_date).isSame(value, 'day'),
    },
    {
      title: 'Número de Factura',
      dataIndex: 'numero_factura',
      key: 'numero_factura',
      render: (text) => (
        <Button type='link' onClick={() => handleOpenModal(text)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Impuesto',
      dataIndex: 'tax',
      key: 'tax',
      render: (value) => `$${value.toFixed(2)}`, // Formatting as currency
      sorter: (a, b) => a.tax - b.tax, // Sorting by tax
      align: 'right', // Align the content to the right
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (value) => `$${value.toFixed(2)}`, // Formatting as currency
      sorter: (a, b) => a.total - b.total, // Sorting by total
      align: 'right', // Align the content to the right
    },
  ];

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv>
        <Table
          columns={columns}
          dataSource={filteredOrders} // Use filteredOrders instead of orders
          rowKey='id'
          loading={loading}
          pagination={{ pageSize: 100 }}
        />
        <Modal
          title='Factura XML Details'
          visible={modalVisible}
          onCancel={handleCloseModal}
          footer={null}
          width={800}
        >
          pdfContent: {pdfContent}
          {loadingPdf ? (
            <Spin size='large' />
          ) : (
            pdfContent && (
              <iframe
                src={`data:application/pdf;base64,${pdfContent}`}
                style={{ width: '100%', height: '600px', border: 'none' }}
                title='Factura PDF'
              />
            )
          )}
        </Modal>
      </AffixDiv>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

export { InvuTable };
