import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { message, Select } from 'antd';
import axios from 'axios';

const AccountingAccountSelect = (props) => {
  const auth = useContext(authContext);
  const [value, setValue] = useState(props.record.accounting_account_id);

  useEffect(() => {
    setValue(props.record.accounting_account_id);
  }, [props.record]);
  const updateSotTxnAccountingAccount = (value) => {
    axios({
      method: 'post',
      url: props.API_domain + 'updateSotTxnAccountingAccount',
      auth: {
        username: auth.email, // Ensure 'auth' object is available in this context
        password: auth.token,
      },
      data: {
        sot_txn_id: props.record.id,
        new_accounting_account: value,
        create_flagged_comment: props.createFlaggedComment ? true : false,
      },
    })
      .then((response) => {
        message.success('Cuenta contable actualizada');
        props.onChange(value, props.record);
      })
      .catch((error) => {
        console.error('Error fetching sources of truth:', error);
      });
  };

  const handleChange = (value) => {
    setValue(value);
    updateSotTxnAccountingAccount(value);
  };
  if (props.displayMode === 'text') {
    let accountName = '';

    if (
      props.accountingAccountOptions &&
      Array.isArray(props.accountingAccountOptions)
    ) {
      const account = props.accountingAccountOptions.find(
        (acc) => acc.id === value
      );
      if (account) {
        accountName = account.name;
      }
    }

    return <span style={{ fontWeight: 'bold' }}>{accountName}</span>;
  }

  return (
    <>
      <Select
        showSearch
        style={{ width: 200 }}
        dropdownStyle={{ width: 300 }}
        dropdownMatchSelectWidth={false}
        placeholder='Elegir cuenta contable'
        optionFilterProp='children'
        allowClear={props.allowClear}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={handleChange}
        value={value}
      >
        {props.accountingAccountOptions.map((account) => (
          <Select.Option key={account.id} value={account.id}>
            {account.name}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export { AccountingAccountSelect };
