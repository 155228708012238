import React, { useContext, useState, useEffect } from 'react';
import { Affix, Button, Col, Layout, Row, Tooltip, message } from 'antd';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { MovementSourceSelect } from './MovementSourceSelect.js';
import { ConciliationTable } from './ConciliationTable.js';
import { FormattedUSD } from './../FormattedUSD.js';
import axios from 'axios';

const { Content } = Layout;

function V2ConciliationView(props) {
  const auth = useContext(authContext);
  const [movementSource1, setMovementSource1] = useState(null);
  const [movementSource2, setMovementSource2] = useState(null);
  const [selectedMovements1, setSelectedMovements1] = useState([]);
  const [selectedMovements2, setSelectedMovements2] = useState([]);
  const [movements1, setMovements1] = useState([]);
  const [movements2, setMovements2] = useState([]);
  const [highlightedMovements, setHighlightedMovements] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const calculateTotal = (items) =>
    items.reduce((total, item) => total + parseFloat(item.total), 0);

  const handleUnselect = (setItems) => {
    setItems([]);
  };

  const isDateClose = (date1, date2, threshold = 1) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const diffTime = Math.abs(d2 - d1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= threshold;
  };

  const isAmountClose = (amount1, amount2, threshold = 0.1) => {
    const diff = Math.abs(amount1 - amount2);
    return diff <= threshold * Math.max(amount1, amount2);
  };

  const stringSimilarity = (str1, str2) => {
    const s1 = str1.toLowerCase();
    const s2 = str2.toLowerCase();
    const pairs1 = new Set(
      s1
        .split('')
        .map((_, i) => s1.slice(i, i + 2))
        .filter((pair) => pair.length === 2)
    );
    const pairs2 = new Set(
      s2
        .split('')
        .map((_, i) => s2.slice(i, i + 2))
        .filter((pair) => pair.length === 2)
    );
    const union = new Set([...pairs1, ...pairs2]);
    const intersection = new Set(
      [...pairs1].filter((pair) => pairs2.has(pair))
    );
    return intersection.size / union.size;
  };

  const getHighlightedMovements = (selectedMovements, allMovements) => {
    if (selectedMovements.length === 0) return [];

    const highlighted = [];
    const dateMatches = [];

    for (const selected of selectedMovements) {
      for (const movement of allMovements) {
        const isDateMatch = isDateClose(
          selected.movement_date,
          movement.movement_date
        );
        const isAmountMatch = isAmountClose(
          parseFloat(selected.total),
          parseFloat(movement.total)
        );
        const isNameMatch =
          stringSimilarity(
            selected.provider_or_consumer_name || '',
            movement.provider_or_consumer_name || ''
          ) > 0.5;

        if (isDateMatch) {
          if (isAmountMatch) {
            highlighted.push(movement);
            if (highlighted.length > selectedMovements.length)
              return highlighted;
          } else {
            dateMatches.push(movement);
          }
        }

        if (isDateMatch && isNameMatch) {
          highlighted.push(movement);
          if (highlighted.length > selectedMovements.length) return highlighted;
        }
      }
    }

    // If we haven't found enough matches, add the date matches
    if (highlighted.length === 0) {
      return dateMatches.slice(0, selectedMovements.length + 1);
    }

    return highlighted;
  };

  useEffect(() => {
    if (selectedMovements1.length > 0) {
      const highlighted = getHighlightedMovements(
        selectedMovements1,
        movements2
      );
      setHighlightedMovements(highlighted);
    } else {
      setHighlightedMovements([]);
    }
  }, [selectedMovements1, movements2]);

  const getMovements = (source, setMovements, setLoading) => {
    setLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'getMovementsForConciliation',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: source,
    })
      .then((response) => {
        setMovements(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching movements:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (movementSource1) {
      getMovements(movementSource1, setMovements1, setLoading1);
    }
  }, [movementSource1]);

  useEffect(() => {
    if (movementSource2) {
      getMovements(movementSource2, setMovements2, setLoading2);
    }
  }, [movementSource2]);

  const handleJoin = () => {
    const movements1 = selectedMovements1.map((movement) => ({
      table_name: movement.source_table,
      id: movement.id,
    }));

    const movements2 = selectedMovements2.map((movement) => ({
      table_name: movement.source_table,
      id: movement.id,
    }));
    axios({
      method: 'post',
      url: props.API_domain + 'createPairwiseRelationship',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        movements1: movements1,
        movements2: movements2,
      },
    })
      .then((response) => {
        message.success('Unión exitosa');
        setMovements1((prevMovements) =>
          prevMovements.filter(
            (m) => !selectedMovements1.some((selected) => selected.id === m.id)
          )
        );
        setMovements2((prevMovements) =>
          prevMovements.filter(
            (m) => !selectedMovements2.some((selected) => selected.id === m.id)
          )
        );
        setSelectedMovements1([]);
        setSelectedMovements2([]);
      })
      .catch((error) => {
        message.error('Ocurrió un error uniendo');
        console.error(error);
      });
  };

  return (
    <Content
      style={{
        margin: '0',
        overflow: 'initial',
        border: 'solid rgb(235,235,235) 1px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Affix>
        <AffixDiv>
          <StyledRow>
            <StyledCol span={12}>
              <Row align='middle' justify='start'>
                <Col>
                  <h2 style={{ marginBottom: 0 }}>💳 Movimientos 1</h2>
                </Col>
                <Col flex='auto'>
                  <MovementSourceSelect
                    API_domain={props.API_domain}
                    onSourceSelect={(source) => {
                      setMovementSource1(source);
                    }}
                  />
                </Col>
              </Row>
            </StyledCol>
            <StyledCol span={12}>
              <Row align='middle' justify='start'>
                <Col>
                  <h2 style={{ marginBottom: 0 }}>💳 Movimientos 2</h2>
                </Col>
                <Col flex='auto'>
                  <MovementSourceSelect
                    API_domain={props.API_domain}
                    onSourceSelect={(source) => {
                      setMovementSource2(source);
                    }}
                  />
                </Col>
              </Row>
            </StyledCol>
          </StyledRow>
        </AffixDiv>
      </Affix>
      <StyledRow style={{ height: 'calc(100% - 105px)' }}>
        <StyledCol span={12}>
          <ScrollableTableContainer>
            <ConciliationTable
              data={movements1}
              loading={loading1}
              selectedItems={selectedMovements1}
              setSelectedItems={setSelectedMovements1}
              highlightedMovements={[]}
              type='transaction'
              API_domain={props.API_domain}
            />
          </ScrollableTableContainer>
        </StyledCol>
        <StyledCol span={12}>
          <ScrollableTableContainer>
            <ConciliationTable
              data={movements2}
              loading={loading2}
              selectedItems={selectedMovements2}
              setSelectedItems={setSelectedMovements2}
              highlightedMovements={highlightedMovements}
              type='transaction'
              API_domain={props.API_domain}
            />
          </ScrollableTableContainer>
        </StyledCol>
      </StyledRow>
      <Affix offsetBottom={0}>
        <AffixDivBottom>
          <Row>
            <Col span={10} style={{ textAlign: 'right' }}>
              {selectedMovements1.length > 0 && (
                <>
                  <Button
                    onClick={() => handleUnselect(setSelectedMovements1)}
                    type='secondary'
                    style={{ marginRight: 10 }}
                  >
                    Deseleccionar
                  </Button>
                  Total ({selectedMovements1.length} movimientos):{' '}
                  <b>
                    <FormattedUSD total={calculateTotal(selectedMovements1)} />
                  </b>
                </>
              )}
            </Col>
            <Col span={4} style={{ textAlign: 'center' }}>
              <Button
                type='primary'
                style={{ marginRight: 10 }}
                disabled={
                  selectedMovements1.length < 1 || selectedMovements2.length < 1
                }
                onClick={handleJoin}
              >
                <Tooltip
                  title={
                    selectedMovements1.length < 1 ||
                    selectedMovements2.length < 1
                      ? 'Selecciona al menos un movimiento de cada tabla'
                      : ''
                  }
                >
                  Unir
                </Tooltip>
              </Button>
            </Col>
            <Col span={10} style={{ textAlign: 'left' }}>
              {selectedMovements2.length > 0 && (
                <>
                  Total ({selectedMovements2.length} movimientos):{' '}
                  <b>
                    <FormattedUSD total={calculateTotal(selectedMovements2)} />
                  </b>
                  <Button
                    onClick={() => handleUnselect(setSelectedMovements2)}
                    type='secondary'
                    style={{ marginLeft: 10 }}
                  >
                    Deseleccionar
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </AffixDivBottom>
      </Affix>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

const AffixDivBottom = styled.div`
  border-top: 1px solid #e8e8e8;
  background-color: white;
  padding-top: 6px;
  padding-left: 14px;
  padding-right: 14px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 225px);
  padding-bottom: 8px;
`;

const ScrollableTableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  height: calc(100% - 105px);
  min-height: 0;
`;

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: row;
  height: calc(100% - 105px);
`;

const StyledCol = styled(Col)`
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export { V2ConciliationView };
