// MovementSourceSelect.js

import { Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';

function MovementSourceSelect({
  API_domain,
  onSourceSelect,
  highlightedColor,
  noPadding,
}) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);

  useEffect(() => {
    fetchMovementSources();
  }, []);

  function fetchMovementSources() {
    axios({
      method: 'POST',
      url: `${API_domain}/getMovementSources`,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        const selectOptions = response.data.map((source) => ({
          label: source.source_name,
          value: JSON.stringify(source), // Store the entire source object as a string
        }));
        setOptions(selectOptions);
      })
      .catch(function (error) {
        console.error('Error fetching movement sources:', error);
      });
  }

  const handleChange = (value) => {
    const selectedSourceObject = JSON.parse(value);
    setSelectedSource(selectedSourceObject);
    onSourceSelect(selectedSourceObject);
  };

  return (
    <span
      style={{
        backgroundColor: highlightedColor,
        padding: noPadding ? 0 : 12,
        borderRadius: 4,
      }}
    >
      <Select
        showSearch
        value={selectedSource ? JSON.stringify(selectedSource) : undefined}
        options={options}
        onChange={handleChange}
        placeholder='Seleccione fuente de movimiento'
        style={{ width: '400px' }}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
      />
    </span>
  );
}

export { MovementSourceSelect };
