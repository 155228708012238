import {
  FilterFilled,
  LoadingOutlined,
  SearchOutlined,
  WarningTwoTone,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Empty,
  Image,
  message,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { DateFilterDropdown } from './../DateFilterDropdown.js';
import { DescriptionSearchDropdown } from './../DescriptionSearchDropdown.js';
import { FormattedUSD } from './../FormattedUSD.js';
import { get_moments_from_month_name } from '../../utils.js';
import './../styles.css';

function ImgOrPDF(props) {
  const [isImage, setIsImage] = useState(true);
  if (!props.record) {
    return (
      <Tooltip placement='topLeft' title={'Foto de factura pendiente!'}>
        <QuestionCircleOutlined />
      </Tooltip>
    );
  }
  if (isImage) {
    return (
      <Image
        width={16}
        height={24}
        src={props.record}
        onError={(event) => setIsImage(false)}
        preview={false}
      />
    );
  } else {
    return (
      <Button
        type='text'
        style={{
          height: '24px',
          'text-align': 'center',
          border: '0px',
          'padding-top': '3px',
          'padding-bottom': '3px',
          'vertical-align': 'middle',
        }}
      >
        <AWithAlign href={props.record} target='_blank'>
          PDF
        </AWithAlign>
      </Button>
    );
  }
}

const colors = [
  'orange',
  'green',
  'cyan',
  'blue',
  'purple',
  'volcano',
  'geekblue',
];

function TransactionsTable(props) {
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const auth = useContext(authContext);
  const location = useLocation();
  const [showProviderFinder, setShowProviderFinder] = useState(
    auth.email === 'noreplyherofacturas@gmail.com' ||
      auth.email === 'jarol@herofacturas.com'
  );

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const imgRef = useRef();

  let category_to_color = {};
  let counter = 0;
  const sorted_cats = Array.from(
    new Set([...items].map((item) => item.category))
  ).sort();
  sorted_cats.forEach(function (cat) {
    category_to_color[cat] = colors[counter];
    counter += 1;
  });
  items.forEach(function (item) {
    if (item.fecha) {
      item.fechaFormatted = new Intl.DateTimeFormat('es-US', {
        dateStyle: 'medium',
      }).format(
        new Date(
          item.fecha.split('/')[0],
          item.fecha.split('/')[1] - 1,
          item.fecha.split('/')[2]
        )
      );
      item.fechaFormattedShort = new Intl.DateTimeFormat('es-US', {
        day: 'numeric',
        month: 'short',
      }).format(
        new Date(
          item.fecha.split('/')[0],
          item.fecha.split('/')[1] - 1,
          item.fecha.split('/')[2]
        )
      );
    }
  });
  function filterCreator(data, column) {
    const columnSet = Array.from(new Set(data.map((item) => item[column])));
    return columnSet.map((item) => ({
      text: item,
      value: item,
    }));
  }

  const rowSelection = {
    selectedRowKeys: props.selectedExpensesInMassEdit,
    onChange: (selectedRowKeys, selectedRows) => {
      props.setSelectedExpensesInMassEdit(selectedRows);
    },
  };

  let gastosColumns = [
    // DESKTOP
    {
      // title: 'Tarjeta',
      dataIndex: 'card',
      key: 'card',
      width: 32,
      ellipsis: {
        showTitle: false,
      },
      filteredValue: props.filters.card ? props.filters.card : null,
      filterIcon: (filtered) => <FilterFilled style={{ marginRight: 14 }} />,
      filters: [
        { text: '💳', value: 'card' },
        { text: '🏧', value: 'atm' },
      ],
      onFilter: (value, record) => {
        let transactionType = record.card_atm_withdrawal ? 'atm' : 'card';
        return transactionType === value;
      },
      render: (text, record) => {
        if (record.card_atm_withdrawal) {
          return (
            <Tooltip placement='right' title='Retiro en cajero'>
              🏧
            </Tooltip>
          );
        } else {
          return (
            <Tooltip placement='right' title='Pagado con tarjeta'>
              💳
            </Tooltip>
          );
        }
      },
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      ellipsis: {
        showTitle: false,
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DescriptionSearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filteredValue: props.filters.descripcion
        ? [props.filters.descripcion]
        : null,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        return record['descripcion']
          .concat(record['provider'])
          .toString()
          .toLowerCase()
          .includes(value[0].toLowerCase());
      },
      render: (expenseName, record) =>
        expenseName == 'SIN DESCRIPCION' ? (
          <Tooltip placement='topLeft' title={'SIN DESCRIPCION'}>
            <span style={{ color: 'gray' }}>
              {'SIN DESCRIPCION'}
              <i>{record.provider ? ' - ' + record.provider : ''} </i>
            </span>
          </Tooltip>
        ) : (
          <Tooltip placement='topLeft' title={expenseName}>
            {expenseName}
          </Tooltip>
        ),
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
      width: 240,
      filteredValue: props.filters.category ? props.filters.category : null,
      filters: filterCreator(items, 'category'),
      ellipsis: true,
      onFilter: (value, record) => {
        if (record.category) {
          return record.category.indexOf(value) === 0;
        } else {
          if (value == null) {
            return true;
          }
          if (value == 'Sin Categoría' && !record.category) {
            return true;
          }
          return false;
        }
      },
      render: (category) => {
        if (category == null) {
          return (
            <span style={{ opacity: '50%' }}>
              <Tag>N/A</Tag>
            </span>
          );
        }
        return <Tag color={category_to_color[category]}>{category}</Tag>;
      },
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaFormatted',
      key: 'fechaFormatted',
      width: 130,
      filteredValue: props.filters.fechaFormatted
        ? props.filters.fechaFormatted
        : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div>
          <DateFilterDropdown
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        </div>
      ),
      onFilter: (value, record) => {
        const current_date = new Date(record.fecha);
        if (typeof value == 'string') {
          let filter_dates = get_moments_from_month_name(value);
          return (
            current_date >= filter_dates[0] && current_date <= filter_dates[1]
          );
        }
        return current_date >= value[0] && current_date <= value[1];
      },
    },
    {
      title: 'Débito',
      dataIndex: 'total',
      key: 'debit',
      align: 'right',
      width: 120,
      // sorter: (a, b) => Math.abs(a.total) - Math.abs(b.total),
      // sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      render: (total) => {
        // Render the value as positive if it's negative
        return total > 0 ? <FormattedUSD total={total} /> : '';
      },
    },
    {
      title: 'Crédito',
      dataIndex: 'total',
      key: 'credit',
      align: 'right',
      width: 120,
      showSorterTooltip: false,
      render: (total) => {
        // Render the value as positive if it's positive
        return total < 0 ? <FormattedUSD total={-total} /> : '';
      },
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      align: 'right',
      width: 120,
      showSorterTooltip: false,
      render: (balance) => {
        return <FormattedUSD total={balance} />;
      },
    },
    {
      title: 'Factura',
      dataIndex: 'factura',
      key: 'factura',
      align: 'center',
      width: 80,
      render: (record) => {
        return <ImgOrPDF record={record} />;
      },
    },
    {
      dataIndex: ['processed'],
      key: 'processed',
      align: 'center',
      width: 50,
      render: (id, expense, rowIndex) => {
        return expense.report_id ? (
          <Tooltip placement='topLeft' title={'Reporte #' + expense.report_id}>
            🗃️
          </Tooltip>
        ) : expense.processing ? (
          <Tooltip
            placement='topLeft'
            title={'Estamos extrayendo los datos de tu factura'}
          >
            {showProviderFinder ? (
              !expense.parsed ? (
                <Badge status='processing' text='' />
              ) : (
                <img
                  src='processing.png'
                  width='20px'
                  ref={imgRef}
                  className={hoveredRowIndex === rowIndex ? 'rotate' : ''}
                ></img>
              )
            ) : (
              <img
                src='processing.png'
                width='20px'
                ref={imgRef}
                className={hoveredRowIndex === rowIndex ? 'rotate' : ''}
              ></img>
            )}
          </Tooltip>
        ) : expense.duplicate ? (
          <Tooltip
            placement='topLeft'
            title={
              <>
                {'Posible factura duplicada. Presente en:'}
                {expense.duplicate_locations_list.map((location, index) => (
                  <div key={index}>
                    {index + 1}. {location}
                  </div>
                ))}
              </>
            }
          >
            <WarningTwoTone twoToneColor='#ffcc00' />
          </Tooltip>
        ) : (
          ''
        );
      },
    },
  ];

  if (auth.personal) {
    gastosColumns.splice(2, 1);
  }

  let axiosSource = axios.CancelToken.source();
  useEffect(() => {
    let cancel; // Declare a cancel variable
    props.setLoaded(false);

    getTransactions((c) => (cancel = c)); // Pass setter function to getTransactions

    return () => {
      cancel && cancel('Canceled due to new request'); // Cancel the request if it exists
    };
  }, [props.submittedToggle, props.route, props.team, props.selectedCard]);

  // useEffect(() => {
  //   props.setExpensesList(items);
  // }, []);

  const getTransactions = (setCancel) => {
    const source = axios.CancelToken.source(); // Create a new CancelToken source

    setCancel(source.cancel); // Set the cancel function
    axios({
      method: 'post',
      url: props.API_domain + props.route, // getUnreportedTransactions or getAllTransactions
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        card_id: props.selectedCard,
      },
      cancelToken: source.token,
    })
      .then((response) => {
        props.setLoaded(true);
        setItems(response.data);
        props.setExpensesList(response.data);
        props.setTableExpenses(response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled');
        } else {
          props.setLoaded(true);
          setError(true);
        }
      });
  };

  const onChange = (pagination, filters, sorter, extra) => {
    props.setTableExpenses(extra['currentDataSource']);
    props.setExpensesList(extra['currentDataSource']);

    const newFilters = {};
    ['card', 'descripcion', 'category', 'fechaFormatted', 'total'].forEach(
      (key) => {
        newFilters[key] =
          filters[key] && filters[key].length > 0 ? filters[key] : null;
      }
    );
    props.updateFilters(newFilters);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!props.isLoaded) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  } else {
    return (
      <>
        <Table
          rowSelection={
            auth.email == 'noreplyherofacturas@gmail.com' ||
            auth.email == 'jarol@herofacturas.com' ||
            (props.team != 'all' &&
              props.team &&
              props.route == 'getUnassignedExpenses')
              ? rowSelection
              : null
          }
          onRow={(record, index) => {
            return {
              onMouseEnter: () => setHoveredRowIndex(index),
              onMouseLeave: () => setHoveredRowIndex(null),
              onClick: () => {
                // if amount is not negative, props. open modal
                if (record.total > 0) {
                  props.openModal(record);
                } else {
                  message.error(
                    'Esta transacción no tiene información adicional.'
                  );
                }
              },
            };
          }}
          onChange={onChange}
          columns={gastosColumns}
          dataSource={items}
          bordered={false}
          rowClassName={(record, index) => {
            return 'cursor-pointer';
          }}
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          }}
          pagination={{ defaultPageSize: 50 }}
          sticky
          size='middle'
          locale={{
            emptyText: (
              <Empty
                style={{ paddingTop: '20vh', paddingBottom: '20vh' }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <div>Selecciona una tarjeta para ver sus transacciones</div>
                  </>
                }
              />
            ),
          }}
        />
      </>
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

const AWithAlign = styled.a``;

export { TransactionsTable };
