// ConciliationTable.js

import React, { useState, useRef } from 'react';
import { Empty, Table, Tooltip, Row, Col, Image, Input, Button } from 'antd';
import styled from 'styled-components/macro';
import { FormattedUSD } from './../FormattedUSD.js';
import {
  CreditCardOutlined,
  InboxOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';

const StyledTable = styled(Table)`
  .highlighted-row-date {
    background-color: #e6f7ff;
  }
  .highlighted-row-amount {
    background-color: #f6ffed;
  }
  .highlighted-row-name {
    background-color: #fff7e6;
  }
  .highlighted-row-multiple {
    background-color: #fff1f0;
  }
`;

const HighlightedCell = styled.div`
  background-color: ${(props) =>
    props.isHighlighted ? '#e6f7ff' : 'transparent'};
`;

function ConciliationTable({
  data,
  loading,
  selectedItems,
  setSelectedItems,
  highlightedMovements,
  type,
  API_domain,
}) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const formatDate = (date) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return date;

    return new Intl.DateTimeFormat('es-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(parsedDate);
  };

  // Function to enable search functionality for each column
  const getColumnSearchProps = (dataIndex, displayName) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${displayName}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex];
      return recordValue
        ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
        : '';
    },
    render: (text) => {
      const value = text ? text.toString() : '';
      return searchedColumn === dataIndex && searchText ? (
        <span>
          {value
            .split(new RegExp(`(${searchText})`, 'gi'))
            .map((fragment, i) =>
              fragment.toLowerCase() === searchText.toLowerCase() ? (
                <span key={i} style={{ backgroundColor: '#ffc069' }}>
                  {fragment}
                </span>
              ) : (
                fragment
              )
            )}
        </span>
      ) : (
        value
      );
    },
  });

  // Handlers for search functionality
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0] || '');
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  // Table columns with search and conditional styling
  const columns = [
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      ...getColumnSearchProps('descripcion', 'Descripción'),
      render: (descripcion, record) => {
        const isHighlighted = highlightedMovements.some(
          (m) => m.id === record.id
        );
        const provider =
          type === 'expense'
            ? record.provider || ''
            : record.provider_or_consumer_name || '';
        const descriptionText = descripcion || record.description || '';
        return (
          <HighlightedCell isHighlighted={isHighlighted}>
            <div>
              {searchedColumn === 'descripcion' && searchText
                ? highlightText(descriptionText, searchText)
                : descriptionText}
            </div>
            <div style={{ color: 'grey' }}>{provider}</div>
          </HighlightedCell>
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        const isHighlighted = highlightedMovements.some(
          (m) => m.id === record.id
        );
        const amount = parseFloat(text);
        const amountColor = amount < 0 ? 'red' : 'green';
        return (
          <HighlightedCell isHighlighted={isHighlighted}>
            <span style={{ color: amountColor }}>
              <FormattedUSD total={text} />
            </span>
          </HighlightedCell>
        );
      },
    },
    {
      title: 'Fecha',
      dataIndex: type === 'expense' ? 'fecha' : 'movement_date',
      key: 'fecha',
      ...getColumnSearchProps(
        type === 'expense' ? 'fecha' : 'movement_date',
        'Fecha'
      ),
      render: (text, record) => {
        const isHighlighted = highlightedMovements.some(
          (m) => m.id === record.id
        );
        const date =
          type === 'expense'
            ? record.fecha || record.date
            : record.movement_date;
        const formattedDate = formatDate(date);
        return (
          <HighlightedCell isHighlighted={isHighlighted}>
            {searchedColumn ===
              (type === 'expense' ? 'fecha' : 'movement_date') && searchText
              ? highlightText(formattedDate, searchText)
              : formattedDate}
          </HighlightedCell>
        );
      },
      sorter: (a, b) => {
        const dateA = new Date(
          type === 'expense' ? a.fecha || a.date : a.movement_date
        );
        const dateB = new Date(
          type === 'expense' ? b.fecha || b.date : b.movement_date
        );
        return dateB - dateA; // Descending order
      },
      defaultSortOrder: 'ascend', // Set default sort order to descending
    },
    {
      title: '',
      dataIndex: 'expand',
      key: 'expand',
      render: () => null,
    },
  ]; // New function to sort data based on highlights
  const sortDataWithHighlights = (data) => {
    return [...data].sort((a, b) => {
      const isHighlightedA = highlightedMovements.some((m) => m.id === a.id);
      const isHighlightedB = highlightedMovements.some((m) => m.id === b.id);

      if (isHighlightedA && !isHighlightedB) return -1;
      if (!isHighlightedA && isHighlightedB) return 1;

      // If both are highlighted or both are not highlighted, sort by date
      const dateA = new Date(
        type === 'expense' ? a.fecha || a.date : a.movement_date
      );
      const dateB = new Date(
        type === 'expense' ? b.fecha || b.date : b.movement_date
      );
      return dateB - dateA; // Descending order
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedItems.map((item) => item.id),
    onChange: (_, selectedRows) => setSelectedItems(selectedRows),
    columnTitle: '',
  };

  const customExpandIcon = (props) => {
    if (props.expanded) {
      return (
        <EyeOutlined
          onClick={(e) => props.onExpand(props.record, e)}
          style={{ transform: 'rotate(180deg)' }}
        />
      );
    }
    return <EyeOutlined onClick={(e) => props.onExpand(props.record, e)} />;
  };

  const expandedRowRender = (record) => (
    <Row>
      <Col span={12}>
        {type === 'expense' ? (
          <>
            <p>
              <strong>Categoría:</strong> {record.category}
            </p>
            <p>
              <strong>Proveedor:</strong> {record.provider}
            </p>
            <p>
              <strong>RUC:</strong> {record.ruc}
            </p>
            <p>
              <strong>Gastante:</strong> {record.gastante}
            </p>
            <p>
              <strong>Fecha de creación:</strong>{' '}
              {formatDate(record.created_at)}
            </p>
          </>
        ) : (
          <>
            <p>
              <strong>Categoría:</strong> {record.categoria}
            </p>
            <p>
              <strong>Proveedor/Consumidor:</strong>{' '}
              {record.provider_or_consumer_name}
            </p>
            <p>
              <strong>ID Proveedor/Consumidor:</strong>{' '}
              {record.provider_or_consumer_id}
            </p>
            <p>
              <strong>Fecha de movimiento:</strong>{' '}
              {formatDate(record.movement_date)}
            </p>
          </>
        )}
      </Col>
      <Col span={12}>
        {type === 'expense' && record.factura && (
          <Image width={200} src={record.factura} />
        )}
        {type !== 'expense' && record.image_url && (
          <Image width={200} src={record.image_url} />
        )}
      </Col>
    </Row>
  );

  // Helper function to highlight search text
  const highlightText = (text, searchText) => {
    const regex = new RegExp(`(${searchText})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === searchText.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: '#ffc069' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };
  return (
    <StyledTable
      columns={columns}
      dataSource={sortDataWithHighlights(data)}
      loading={loading}
      rowKey={(record) => record.id}
      pagination={{ defaultPageSize: 20 }}
      rowSelection={rowSelection}
      style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
      rowClassName={(record) =>
        highlightedMovements.some((m) => m.id === record.id)
          ? 'highlighted-row'
          : ''
      }
      expandable={{
        expandedRowRender,
        expandIcon: customExpandIcon,
      }}
      locale={{
        emptyText: (
          <Empty
            style={{ paddingTop: '20vh', paddingBottom: '20vh' }}
            image={
              type === 'expense' ? (
                <InboxOutlined style={{ fontSize: '64px' }} />
              ) : (
                <CreditCardOutlined style={{ fontSize: '64px' }} />
              )
            }
            description={
              type === 'expense' ? (
                <>
                  Selecciona un <b>equipo para ver los gastos</b> pendientes por
                  conciliar
                </>
              ) : (
                <>
                  Selecciona una{' '}
                  <b>fuente de movimiento para ver los movimientos</b>{' '}
                  pendientes por conciliar
                </>
              )
            }
          />
        ),
      }}
    />
  );
}

export { ConciliationTable };
