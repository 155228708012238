import React, { useContext, useState } from 'react';
import { Affix, Col, Layout, message, Row, Button } from 'antd';
import styled from 'styled-components/macro';
import { BankView } from './BankView.js';
import { authContext } from '../ProvideAuth.js';
import axios from 'axios';

const { Content } = Layout;

function MoneyMovementsView(props) {
  const [activeComponent, setActiveComponent] = useState('card');
  const auth = useContext(authContext);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'bank':
        return <BankView API_domain={props.API_domain} type_of_mm='bank' />;
      case 'card':
        return <BankView API_domain={props.API_domain} type_of_mm='card' />;
      default:
        return <BankView API_domain={props.API_domain} />;
    }
  };

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        // borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        // borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('card')}
                isActive={activeComponent === 'card'}
              >
                Tarjetas
              </NavButton>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('bank')}
                isActive={activeComponent === 'bank'}
              >
                Banco
              </NavButton>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {/* You can add other elements or controls here */}
            </Col>
          </Row>
        </AffixDiv>
      </Affix>

      <div>{renderComponent()}</div>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
  // border-left: solid rgb(235, 235, 235) 1px;
`;

// Styled button component to reflect the active state
const NavButton = styled(Button)`
  color: ${({ isActive }) =>
    isActive ? 'rgb(116, 116, 205)' : '#000'}; // Active button has blue color
  font-weight: ${({ isActive }) =>
    isActive ? '600' : 'normal'}; // Active button has bold text
  font-size: 16px;
`;

export { MoneyMovementsView };
