import { Button, Col, Form, Input, Row, Select } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { LoginFormFeedback } from './LoginFormFeedback';

function LoginView(props) {
  let [loginSent, setLoginSent] = useState(false);
  let [email, setEmail] = useState('');
  let [phone, setPhone] = useState('');
  let [validateStatus, setValidateStatus] = useState(null);
  let [helpMessage, setHelpMessage] = useState(null);
  let [submitted, setSubmitted] = useState(false);
  let [loginChoice, setLoginChoice] = useState(null);
  const [form] = Form.useForm();
  const { Option } = Select;

  const onFinish = (values) => {
    const data = { email: values.email.replace(' ', '') };
    setEmail(values.email);
    setSubmitted(true);
    axios({
      method: 'post',
      url: props.API_domain + 'sendEmail',
      data: data,
    })
      .then((response) => {
        setLoginSent(true);
        console.log(response);
      })
      .catch(function (response) {
        setSubmitted(false);
        setValidateStatus('error');
        const errorMessage =
          response.message == 'Network Error'
            ? 'Hay un problema con tu conexión'
            : 'Este correo no está en nuestra base de datos';
        setHelpMessage(errorMessage);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function generateOTC(phone_no) {
    setSubmitted(true);
    setPhone(phone_no);
    axios({
      method: 'post',
      url: props.API_domain + 'generateOTC',
      data: { phone_no: phone_no },
    })
      .then((response) => {
        setLoginSent(true);
        console.log(response);
      })
      .catch(function (response) {
        setSubmitted(false);
        setValidateStatus('error');
        const errorMessage = 'No está en nuestra base de datos'; // todo: refine this
        setHelpMessage(errorMessage);
      });
  }

  return (
    <PageContainer>
      <Logo src='./HERO_LOGO-03.png' alt='Hero' />
      <LoginBox>
        {submitted ? (
          <LoginFormFeedback
            API_domain={props.API_domain}
            loginChoice={loginChoice}
            email={email}
            phone_no={phone}
            loginSent={loginSent}
          />
        ) : loginChoice == null ? (
          <div>
            <AccedaDiv>Acceda a su cuenta de Hero</AccedaDiv>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ChoiceButton
                type='primary'
                onClick={() => setLoginChoice('whatsapp')}
              >
                WhatsApp
              </ChoiceButton>
              <ChoiceButton
                type='primary'
                onClick={() => setLoginChoice('email')}
              >
                Email
              </ChoiceButton>
            </div>

            <div style={{ marginBottom: 10 }}>
              No tienes una cuenta todavía?{' '}
              <a href='https://www.herofacturas.com' target='_blank'>
                Entérate
              </a>
            </div>
          </div>
        ) : (
          <>
            <div>
              <AccedaDiv>Acceda a su cuenta de Hero</AccedaDiv>
              <Form
                name='loginForm'
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                form={form}
              >
                {loginChoice == 'email' ? (
                  <>
                    <Form.Item
                      label='Email'
                      name='email'
                      validateStatus={validateStatus}
                      help={helpMessage}
                      initialValue={email}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item>
                      <LoginButton type='primary' htmlType='submit' block>
                        Login
                      </LoginButton>
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col span={7} style={{ paddingRight: 4 }}>
                        <Form.Item
                          name='countryCode'
                          label='País'
                          initialValue={'507'}
                          rules={[
                            {
                              required: true,
                              message: 'Please select a country code!',
                            },
                          ]}
                        >
                          <Select
                            // showSearch
                            defaultValue='507'
                          >
                            <Option value='1'>
                              <span role='img' aria-label='United States'>
                                🇺🇸
                              </span>{' '}
                              +1
                            </Option>
                            <Option value='57'>
                              <span role='img' aria-label='United Kingdom'>
                                🇨🇴
                              </span>{' '}
                              +57
                            </Option>
                            <Option value='507'>
                              <span role='img' aria-label='Germany'>
                                🇵🇦
                              </span>{' '}
                              +507
                            </Option>
                            <Option value='34'>
                              <span role='img' aria-label='Espana'>
                                🇪🇸
                              </span>{' '}
                              +34
                            </Option>
                            <Option value='58'>
                              <span role='img' aria-label='Venezuela'>
                                🇻🇪
                              </span>{' '}
                              +58
                            </Option>
                            <Option value='55'>
                              <span role='img' aria-label='Brazil'>
                                🇧🇷
                              </span>{' '}
                              +55
                            </Option>
                            <Option value='39'>
                              <span role='img' aria-label='Italy'>
                                🇮🇹
                              </span>{' '}
                              +39
                            </Option>
                            <Option value='44'>
                              <span role='img' aria-label='UK'>
                                🇬🇧
                              </span>{' '}
                              +44
                            </Option>
                            <Option value='971'>
                              <span role='img' aria-label='UAE'>
                                🇦🇪
                              </span>{' '}
                              +971
                            </Option>
                            {/* Add more options for other countries here */}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={17}>
                        <Form.Item
                          name='whatsappNumber'
                          label='Número de WhatsApp (sin guiones)'
                          help={helpMessage}
                          rules={[
                            {
                              required: true,
                              message:
                                'Por favor ingresa tu número de WhatsApp',
                            },
                          ]}
                        >
                          <Input placeholder='66123456' />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <LoginButton
                        htmlType='submit'
                        onClick={() => {
                          generateOTC(
                            form.getFieldValue('countryCode') +
                              form.getFieldValue('whatsappNumber')
                          );
                        }}
                        style={{
                          backgroundColor: '#25D366',
                          borderColor: '#25D366',
                          color: 'white',
                          fontWeight: 'bold', //todo: onpress enter, submit
                        }}
                        block
                      >
                        Login
                      </LoginButton>
                    </Form.Item>
                  </>
                )}
              </Form>
            </div>

            <div style={{ marginBottom: 10 }}>
              No tienes una cuenta todavía?{' '}
              <a href='https://www.herofacturas.com' target='_blank'>
                Entérate
              </a>
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setLoginChoice(null);
              }}
            >
              Cambiar forma de login
            </div>
          </>
        )}
      </LoginBox>
    </PageContainer>
  );
}

// Custom styling for the page container
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5; /* Same as the OnboardingForm component */
  padding: 20px;
`;

const LoginBox = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #ffffff; /* Same as the OnboardingForm component */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Same as the OnboardingForm component */
`;

const Logo = styled.img`
  width: 200px; /* Same as the OnboardingForm component */
  margin-bottom: 20px;
`;

const AccedaDiv = styled.div`
  font-weight: bold;
  text-align: left;
  margin-top: 4px;
  margin-bottom: 10px;
  color: rgb(50, 50, 50);
`;

const LoginButton = styled(Button)`
  margin-top: 3px;
`;

const ChoiceButton = styled(Button)`
  height: 105px;
  width: 150px;
  margin: 20px;
  font-weight: 500;
  border-radius: 8px;
`;

export { LoginView };
