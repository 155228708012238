import {
  LoadingOutlined,
  SearchOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Spin, message, Table, Tooltip } from 'antd';
import axios from 'axios';
import { DateFilterDropdown } from '../DateFilterDropdown.js';
import { DescriptionSearchDropdown } from '../DescriptionSearchDropdown.js';
import { authContext } from '../../ProvideAuth.js';
import { get_moments_from_month_name } from '../../utils.js';
import { FormattedUSD } from '../FormattedUSD.js';
import { AccountingAccountSelect } from '../AccountingAccountSelect.js';
import '../styles.css';

const CustomEmptyComponent = () => (
  <div style={{ textAlign: 'center', padding: 20 }}>
    <SmileOutlined style={{ fontSize: 56, color: '#7474cd' }} />
    <p style={{ marginTop: 16 }}>Todo claro por ahora!</p>
  </div>
);

const AccountingInboxTable = (props) => {
  const [flagging, setFlagging] = useState([]); // holds the key to the row that is flagging
  const [data, setData] = useState(props.data);
  const auth = useContext(authContext);
  const [accountingAccountOptions, setAccountingAccountOptions] = useState([]);
  const [showAccountSelect, setShowAccountSelect] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  // console.log(accountingAccountOptions);
  const info = () => {
    messageApi.info('Escoge la categoría correcta para esta transacción');
  };

  const fetchAccountingAccountOptions = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getAccountingAccountsOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { client_id: props.clientId },
    })
      .then((response) => {
        setAccountingAccountOptions(response.data);
      })
      .catch((error) => {
        console.error('Error fetching accounting accounts:', error);
      });
  };

  useEffect(() => {
    fetchAccountingAccountOptions();
  }, []);

  const updateSotTxnFlagged = (record) => {
    setFlagging([...flagging, record.key]);
    axios({
      method: 'post',
      url: props.API_domain + 'flagSotTxn',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { sot_txn_id: record.sotTxnId, add_comment: true },
    })
      .then((response) => {
        setFlagging(flagging.filter((key) => key !== record.key));
        // Update local state for the flagged status
        const updatedData = data.filter((item) => item.key !== record.key);
        setData(updatedData);
        props.setRefresh(!props.refresh);
      })
      .catch((error) => {
        console.error('Error flagging transaction: ', error);
      });
  };

  const columns = [
    {
      title: 'Fuente',
      dataIndex: 'sources_of_truth_zoho_name',
      key: 'sources_of_truth_zoho_name',
      width: 220,
    },
    {
      title: 'Descripción',
      dataIndex: 'Descripcion',
      key: 'Descripcion',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DescriptionSearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.Descripcion.toLowerCase().includes(value.toLowerCase()),
      render: (descripcion) => (
        <Tooltip placement='topLeft' title={descripcion}>
          {descripcion}
        </Tooltip>
      ),
    },
    {
      title: 'Ingreso',
      dataIndex: 'Credito',
      key: 'Credito',
      width: 100,
      showSorterTooltip: false,
      sorter: (a, b) => parseFloat(a.Credito || 0) - parseFloat(b.Credito || 0),
      render: (credito) =>
        credito && <FormattedUSD total={parseFloat(credito)} />,
    },
    {
      title: 'Gasto',
      dataIndex: 'Debito',
      key: 'Debito',
      width: 100,
      showSorterTooltip: false,
      sorter: (a, b) => parseFloat(a.Debito || 0) - parseFloat(b.Debito || 0),
      render: (debito) => debito && <FormattedUSD total={parseFloat(debito)} />,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaFormatted',
      key: 'fechaFormatted',
      width: 120,
      sorter: (a, b) => new Date(a.fechaFormatted) - new Date(b.fechaFormatted),
      showSorterTooltip: false,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const current_date = new Date(record.fechaFormatted);
        if (typeof value == 'string') {
          let filter_dates = get_moments_from_month_name(value);
          return (
            current_date >= filter_dates[0] && current_date <= filter_dates[1]
          );
        }
        return current_date >= value[0] && current_date <= value[1];
      },
    },
    {
      title: 'Cuenta Contable',
      dataIndex: 'accounting_account_id',
      key: 'accounting_account_id',
      width: 240,
      render: (_, record) => {
        return props.previousFlagged ? (
          // i want to show the account name here. it is in the accountingAccountOptions array, using accounting_account_id as the key
          <div>
            {
              accountingAccountOptions.find(
                (acc) => acc.id === record.accounting_account_id
              )?.name
            }{' '}
            ✅
          </div>
        ) : (
          <>
            <AccountingAccountSelect
              API_domain={props.API_domain}
              accountingAccountOptions={accountingAccountOptions}
              record={record}
              allowClear={false}
              onChange={() => {}}
              displayMode={showAccountSelect}
              createFlaggedComment={true}
            />
          </>
        );
      },
    },
    {
      title: 'Cliente',
      dataIndex: 'flagged',
      key: 'flagged',
      width: 170,
      render: (_, record) => {
        return props.previousFlagged ? (
          <div>{record.comments}</div>
        ) : (
          <>
            {flagging.includes(record.key) ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    spin
                    style={{ color: 'black', marginLeft: 12 }}
                  />
                }
              />
            ) : (
              <div>
                <Tooltip
                  placement='topRight'
                  title='Confirma que la categorización es correcta'
                >
                  <Button
                    className='icon-button'
                    onClick={() => {
                      updateSotTxnFlagged(record);
                      message.success('Categorizada guardada');
                    }}
                  >
                    👍
                  </Button>
                </Tooltip>
                <Tooltip placement='topLeft' title='Enviar comentario'>
                  <Button
                    className='icon-button'
                    onClick={() => {
                      props.setShowCommentModal(true);
                      props.setCommentModalSotTxn(record);
                    }}
                  >
                    💬
                  </Button>
                </Tooltip>
              </div>
            )}
          </>
        );
      },
    },
  ];
  const customLocale = {
    emptyText: <CustomEmptyComponent />,
  };
  return (
    <>
      {contextHolder} {/* For the message component */}
      <Table
        dataSource={data}
        columns={columns}
        pagination={{ defaultPageSize: 50 }}
        style={{ width: '100%' }}
        loading={props.loading}
        locale={data.length === 0 ? customLocale : {}}
      />
    </>
  );
};

export { AccountingInboxTable };
