import {
  Affix,
  Button,
  Col,
  Input,
  Layout,
  Row,
  message,
  Table,
  Tabs,
  Typography,
} from 'antd';
import { DownloadOutlined, MailOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { LoadingOutlined } from '@ant-design/icons';
import { IncomeStatementTable2 } from './IncomeStatementTable2.js';
import { BalanceSheetTable2 } from './BalanceSheetTable2.js';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatDateSpanishLong } from '../../utils';
import EmailReviewModal from '../EmailComposer/EmailReviewModal.js';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
function AccountingFinancialStatementsView(props) {
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [hasFinancialStatements, setHasFinancialStatements] = useState();
  const [accountingClientCompany, setAccountingClientCompany] = useState(null);
  const [incomeStatementData, setIncomeStatementData] = useState(null);
  const [balanceSheetData, setBalanceSheetData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingIncomeStatement, setLoadingIncomeStatement] = useState(true);
  const [loadingBalanceSheet, setLoadingBalanceSheet] = useState(true);
  const [email, setEmail] = useState('');
  const [
    incomeStatementRecentTimePeriods,
    setIncomeStatementRecentTimePeriods,
  ] = useState([]);
  const [balanceSheetRecentTimePeriods, setBalanceSheetRecentTimePeriods] =
    useState([]);
  const auth = useContext(authContext);
  useEffect(() => {
    const getUserAccountingClientCompanyInfo = async () => {
      try {
        const response = await axios.post(
          props.API_domain + 'getUserAccountingClientCompanyInfo',
          {},
          {
            auth: {
              username: auth.email,
              password: auth.token,
            },
          }
        );
        const {
          accounting_client_id,
          accounting_client_company_id,
          name,
          ruc,
          logo_image_url,
        } = response.data;
        if (accounting_client_id) {
          setAccountingClientCompany({
            accounting_client_id,
            accounting_client_company_id,
            name,
            ruc,
            logo_image_url,
          });
          setHasFinancialStatements(true);
        } else {
          setHasFinancialStatements(false);
        }
        setLoading(false);
      } catch (error) {
        message.error('Error al obtener la información de la empresa contable');
        console.error('Error fetching accounting client:', error);
      }
    };

    getUserAccountingClientCompanyInfo();
  }, [auth, props.API_domain]);

  useEffect(() => {
    const trackingData = {
      event: 'seeAccountingFinancialStatements',
      properties: {},
    };
    axios({
      method: 'post',
      url: props.API_domain + 'trackFrontEndEvent',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: trackingData,
    })
      .then((response) => {
        // console.log('Event tracked:', response);
      })
      .catch((error) => {
        console.error('Error tracking event:', error);
      });
  }, [auth, props.API_domain]);

  ///////////////////////////////////////// CODE TO RENDER OBSCURE DATA TO SHOW FOR HERO DEMO //////////////////////////////////////////
  const subcategoryMapping = {
    // INGRESOS: 'INCOME',
    'Ingresos por Servicios de Fisioterapia': 'Ingresos por venta de comida',
    // GASTOS: 'EXPENSES',
    // 'XIII Mes': 'Christmas Bonus',
    // 'Servicios Electricos': 'Electric Services',
    // 'Atención a empleados': 'Employee Care',
    // 'Impuestos Municipales': 'Municipal Taxes',
    // 'Cuotas o Suscripciones': 'Subscriptions',
    // Impuestos: 'Taxes',
    // 'Gastos Legales': 'Legal Expenses',
    // 'Utiles de oficina': 'Office Supplies',
    // 'Publicidad y marketing': 'Marketing Expenses',
    // 'Cargos y tasas bancarias': 'Bank Fees',
    // 'Publicidad municipal': 'Municipal Advertising',
    // 'Gastos de TI y de Internet': 'IT and Internet Expenses',
    // 'Gastos de alquiler': 'Rent Expenses',
    // 'Reparación y mantenimiento': 'Repair and Maintenance',
    // Uniformes: 'Uniforms',
    // 'Amort. Gastos Organizacionales': 'Amort. Organizational Expenses',
    // 'Honorarios Contables': 'Accounting Fees',
    // Salarios: 'Salaries',
    // 'Aseo y Limpieza': 'Cleaning Services',
    // 'Gasto de depreciación': 'Depreciation Expense',
    // 'Prestaciones laborales': 'Employee Benefits',
    // 'Honorarios Profesionales': 'Professional Fees',
    // 'Servicios de agua': 'Water Services',
    // 'Gastos Varios': 'Miscellaneous Expenses',
    'Honorarios profesionales Pablo': 'Honorarios profesionales Juan Perez',
    'Honorarios profesionales Grerys': 'Honorarios profesionales Maria Jimenez',
    // 'Servicios profesionales': 'Professional Services',
    'Honorarios profesionales Cilia': 'Honorarios profesionales Michael Jordan',
    'Insumos de fisioterapeutas': 'Insumos de producción',
    // 'UTILIDAD NETA': 'NET INCOME',
  };

  const obscureSubcategory = (subcategory) => {
    return subcategoryMapping[subcategory] || subcategory;
  };

  const obscureIncomeStatementData = (data, factor) => {
    const obscureValuesRecursively = (node) => ({
      ...node,
      subcategory: obscureSubcategory(node.subcategory),
      values: node.values.map((val) => (val * factor).toFixed(2)),
      children: node.children.map(obscureValuesRecursively),
    });

    return data.map(obscureValuesRecursively);
  };
  //////////////// halfstep
  const balanceSheetSubcategoryMapping = {
    // ACTIVOS: 'ASSETS',
    // 'Otras cuentas por cobrar': 'Other Receivables',
    // 'Gastos Organizacionales': 'Organizational Expenses',
    // 'Amort. Gastos Organ.': 'Amort. Organizational Expenses',
    // 'Cuenta por cobrar Yappy': 'Receivable Yappy',
    // 'Cuenta por cobrar Tarjeta': 'Receivable Card',
    // Efectivo: 'Cash',
    // 'Impuestos adelantados': 'Prepaid Taxes',
    // 'Cuenta por cobrar ACH': 'Receivable ACH',
    'Tarjeta Hero Salomon Cohen': 'Tarjeta Hero Michael Jordan',
    // 'Banco General': 'General Bank',
    'Tarjeta Hero Pablo Lima': 'Tarjeta Hero Juan Perez',
    // Bac: 'BAC',
    // 'Mobiliario de oficina': 'Office Furniture',
    // 'Depreciacion Mob. de Oficina': 'Deprec. Office Furniture',
    'Equipo de Fisoterapia': 'Equipo de cocina',
    'Depreciacion Equi. de Fisioterapia': 'Depreciacion Equi. de Cocina',
    'Equipo de Gym': 'Mobiliario restaurante',
    'Depreciacion Equi. de Gym': 'Depreciacion Mobiliario restaurante',
    // 'Mejoras al local arrendado': 'Improvements to Rented Premises',
    // 'Dep. Mejoras al local arrendado': 'Deprec. Improvements to Premises',
    // 'Equipo de Oficina': 'Office Equipment',
    // 'Depreciacion Equi. de Oficina': 'Deprec. Office Equipment',
    // Letreros: 'Signs',
    // 'Depreciacion Letreros': 'Deprec. Signs',
    // PASIVOS: 'LIABILITIES',
    'Honorarios por pagar Cilia': 'Honorarios por pagar Juan Perez',
    'Honorarios por pagar Salo': 'Honorarios por pagar Michael Jordan',
    'Honorarios por pagar Grerys': 'Honorarios por pagar Maria Jimenez',
    'Honorarios por pagar Pablo': 'Honorarios por pagar Linda Perez',
    'Cuenta por pagar Infinity Eq': 'Cuenta por pagar Compañía X',
    'Cuenta por pagar Cilia Amar': 'Cuenta por pagar Compañía Z',
    // 'Anticipio de clientes': 'Customer Advances',
    // 'Seguro social por pagar': 'Social Security Payable',
    'Aporte Accionista Pablo Lima': 'Aporte Accionista Peter Pan',
    'Aporte Accionista Cilia Amar': 'Aporte Accionista Juan Perez Sr.',
    // PATRIMONIO: 'EQUITY',
  };

  const obscureBalanceSheetSubcategory = (subcategory) => {
    return balanceSheetSubcategoryMapping[subcategory] || subcategory;
  };

  const obscureBalanceSheetData = (data, factor) => {
    const obscureValuesRecursively = (node) => ({
      ...node,
      subcategory: obscureBalanceSheetSubcategory(node.subcategory),
      values: node.values.map((val) => (val * factor).toFixed(2)),
      children: node.children.map(obscureValuesRecursively),
    });

    return data.map(obscureValuesRecursively);
  };

  ///////////////////////////////////////// end CODE TO RENDER OBSCURE DATA TO SHOW FOR HERO DEMO //////////////////////////////////////////

  useEffect(() => {
    const keyData = (rawData) => {
      const generateKeys = (data, parentKey = '') => {
        return data.map((item, index) => {
          const key = parentKey ? `${parentKey}-${index + 1}` : `${index + 1}`;
          const children = item.children
            ? generateKeys(item.children, key)
            : [];
          return { ...item, key, children };
        });
      };
      return generateKeys(rawData);
    };

    if (
      accountingClientCompany &&
      accountingClientCompany.accounting_client_id
    ) {
      const getIncomeStatementData = async () => {
        try {
          const response = await axios.post(
            `${props.API_domain}getIncomeStatementData`,
            {
              client_id:
                accountingClientCompany.accounting_client_id == 4
                  ? 10
                  : accountingClientCompany.accounting_client_id,
            },
            {
              auth: {
                username: auth.email,
                password: auth.token,
              },
            }
          );
          const { incomeStatementData, timePeriods } = response.data;

          const recentMonthsCount = 5;
          const recentTimePeriods = timePeriods.slice(-recentMonthsCount);
          setIncomeStatementRecentTimePeriods(recentTimePeriods);

          const sliceValuesRecursively = (node, recentMonthsCount) => ({
            ...node,
            values: node.values.slice(-recentMonthsCount),
            children: node.children.map((child) =>
              sliceValuesRecursively(child, recentMonthsCount)
            ),
          });

          const filteredIncomeStatementData = incomeStatementData.map(
            (category) => sliceValuesRecursively(category, recentMonthsCount)
          );
          let keyedIncomeStatementData = [];
          if (accountingClientCompany.accounting_client_id == 4) {
            const obscuredIncomeStatementData = obscureIncomeStatementData(
              filteredIncomeStatementData,
              0.62
            );

            keyedIncomeStatementData = keyData(obscuredIncomeStatementData);
          } else {
            keyedIncomeStatementData = keyData(filteredIncomeStatementData);
          }
          setIncomeStatementData(keyedIncomeStatementData);
          setLoadingIncomeStatement(false);
        } catch (error) {
          console.error('Error fetching income statement data:', error);
          setLoadingIncomeStatement(false);
        }
      };

      const getBalanceSheetData = async () => {
        try {
          const response = await axios.post(
            `${props.API_domain}getBalanceSheetData`,
            {
              client_id:
                accountingClientCompany.accounting_client_id == 4
                  ? 10
                  : accountingClientCompany.accounting_client_id,
            },
            {
              auth: {
                username: auth.email,
                password: auth.token,
              },
            }
          );

          const { balanceSheetData, timePeriods } = response.data;

          const recentMonthsCount = 5;
          const recentTimePeriods = timePeriods.slice(-recentMonthsCount);
          setBalanceSheetRecentTimePeriods(recentTimePeriods);

          const sliceValuesRecursively = (node, recentMonthsCount) => ({
            ...node,
            values: node.values.slice(-recentMonthsCount),
            children: node.children.map((child) =>
              sliceValuesRecursively(child, recentMonthsCount)
            ),
          });

          const filteredBalanceSheetData = balanceSheetData.map((category) =>
            sliceValuesRecursively(category, recentMonthsCount)
          );
          let keyedBalanceSheetData = [];
          if (accountingClientCompany.accounting_client_id == 4) {
            const obscuredBalanceSheetData = obscureBalanceSheetData(
              filteredBalanceSheetData,
              0.62
            );

            keyedBalanceSheetData = keyData(obscuredBalanceSheetData);
          } else {
            keyedBalanceSheetData = keyData(filteredBalanceSheetData);
          }
          setBalanceSheetData(keyedBalanceSheetData);
          setLoadingBalanceSheet(false);
        } catch (error) {
          console.error('Error fetching balance sheet data:', error);
          setLoadingBalanceSheet(false);
        }
      };

      getIncomeStatementData();
      getBalanceSheetData();
    }
  }, [accountingClientCompany, auth.email, auth.token, props.API_domain]);

  const getLastDayOfMonth = (yearMonth) => {
    const [year, month] = yearMonth.split('-').map(Number);

    // Create a new date object set to the first day of the next month
    const date = new Date(year, month, 1);

    // Subtract one day to get the last day of the given month
    date.setDate(date.getDate() - 1);

    // Format the date to YYYY-MM-DD
    const lastDay = date.toISOString().split('T')[0];

    return lastDay;
  };

  const generatePDF = (outputOnlyBlob = false) => {
    const doc = new jsPDF();

    // Function to load the Georgia font
    const loadFont = (url) => {
      return fetch(url)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          let binary = '';
          const bytes = new Uint8Array(buffer);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          return window.btoa(binary);
        });
    };

    const addGeorgiaFont = (base64) => {
      doc.addFileToVFS('Georgia.ttf', base64);
      doc.addFont('Georgia.ttf', 'Georgia', 'normal');
    };

    const addCoverPage = (doc) => {
      return new Promise((resolve) => {
        const accountingClientLogoSrc =
          'accounting_client_logo_' +
          accountingClientCompany.accounting_client_id +
          '.png';
        const heroLogoSrc = 'heroLogoSmall.png';
        const title = `${accountingClientCompany.name}`;

        const logo1 = new Image();
        const logo2 = new Image();

        let logo1Loaded = false;
        let logo2Loaded = false;

        const drawLogos = () => {
          const pageWidth = doc.internal.pageSize.getWidth();
          const xOffset1 = 10;
          const yOffset = 10;

          let currentX = xOffset1;
          let maxHeight = 0;

          if (logo1Loaded) {
            const { width: logoWidth1, height: logoHeight1 } =
              calculateLogoDimensions(logo1);
            doc.addImage(
              logo1.src,
              'PNG',
              currentX,
              yOffset,
              logoWidth1,
              logoHeight1
            );
            currentX += logoWidth1 + 10;
            maxHeight = Math.max(maxHeight, logoHeight1);
          }

          if (logo2Loaded) {
            const { width: logoWidth2, height: logoHeight2 } =
              calculateLogoDimensions(logo2);
            doc.addImage(
              logo2.src,
              'PNG',
              currentX,
              yOffset,
              logoWidth2,
              logoHeight2
            );
            maxHeight = Math.max(maxHeight, logoHeight2);
          }

          doc.setFont('Georgia');
          doc.setFontSize(20);
          doc.text(title, 20, maxHeight + 30);
          doc.text(
            `Reporte contable al ${formatDateSpanishLong(
              getLastDayOfMonth(
                incomeStatementRecentTimePeriods[
                  incomeStatementRecentTimePeriods.length - 1
                ]
              )
            )}`,
            20,
            maxHeight + 48
          );
          doc.setFontSize(12);
          resolve();
        };

        const calculateLogoDimensions = (img) => {
          const aspectRatio = img.width / img.height;
          const maxLogoHeight = 50;
          const maxLogoWidth = 100;

          let width = maxLogoHeight * aspectRatio;
          let height = maxLogoHeight;

          if (width > maxLogoWidth) {
            width = maxLogoWidth;
            height = maxLogoWidth / aspectRatio;
          }

          return { width, height };
        };

        logo1.onload = () => {
          logo1Loaded = true;
          if (logo2Loaded || logo2.complete) drawLogos();
        };
        logo1.onerror = () => {
          console.warn('Failed to load accounting client logo');
          if (logo2Loaded || logo2.complete) drawLogos();
        };

        logo2.onload = () => {
          logo2Loaded = true;
          if (logo1Loaded || logo1.complete) drawLogos();
        };
        logo2.onerror = () => {
          console.warn('Failed to load hero logo');
          if (logo1Loaded || logo1.complete) drawLogos();
        };

        logo1.src = accountingClientLogoSrc;
        logo2.src = heroLogoSrc;

        // In case both images fail to load or are already cached and complete
        setTimeout(drawLogos, 1000);
      });
    };

    const convertTableData = (data, timePeriods) => {
      const formattedData = [];

      const processRow = (row, level = 0) => {
        formattedData.push({
          subcategory: row.subcategory,
          values: row.values.map((val) => parseFloat(val).toFixed(2)), // Format values to 2 decimals
          level: level,
          isParent: row.children.length > 0,
        });
        row.children.forEach((child) => processRow(child, level + 1));
      };

      data.forEach((row) => processRow(row));
      return formattedData;
    };

    const createTable = (doc, data, timePeriods, startY) => {
      doc.autoTable({
        head: [
          [
            { content: 'Cuenta', styles: { halign: 'left' } },
            ...timePeriods.map((period) => ({
              content: period,
              styles: { halign: 'right' },
            })),
          ],
        ],
        body: data.map((item) => [
          {
            content: item.subcategory,
            styles: {
              fontStyle: item.isParent ? 'bold' : 'normal',
              cellPadding: {
                left: item.level * 10,
                right: 0,
                top: 2,
                bottom: 2,
              },
              halign: 'left',
            },
          },
          ...item.values.map((val) => ({
            content: val,
            styles: {
              fontStyle: item.isParent ? 'bold' : 'normal',
              halign: 'right',
            },
          })),
        ]),
        startY: startY,
        headStyles: { fillColor: [116, 116, 199] },
      });
      return doc.previousAutoTable.finalY;
    };

    const balanceSheetDataForPDF = convertTableData(
      balanceSheetData,
      balanceSheetRecentTimePeriods
    );
    const incomeStatementDataForPDF = convertTableData(
      incomeStatementData,
      incomeStatementRecentTimePeriods
    );

    const addFooter = (doc) => {
      const exportDate = `Exportado el ${formatDateSpanishLong(new Date())}`;
      const pageCount = doc.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(
          `${i}`,
          doc.internal.pageSize.width - 10,
          doc.internal.pageSize.height - 10,
          { align: 'right' }
        );
        doc.text(exportDate, 10, doc.internal.pageSize.height - 10);
      }
    };

    return loadFont('./georgia.ttf')
      .then((base64) => {
        addGeorgiaFont(base64); // Add the Georgia font
        return addCoverPage(doc);
      })
      .then(() => {
        doc.addPage();
        doc.setFontSize(16);
        doc.text('Balance', 16, 20);

        let finalY = createTable(
          doc,
          balanceSheetDataForPDF,
          balanceSheetRecentTimePeriods,
          30
        );

        doc.addPage();
        doc.setFontSize(16);
        doc.text('Estado de Resultados', 16, 20);

        createTable(
          doc,
          incomeStatementDataForPDF,
          incomeStatementRecentTimePeriods,
          30
        );

        // Add page numbers
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.text(
            `${i}`,
            doc.internal.pageSize.width - 10,
            doc.internal.pageSize.height - 10,
            { align: 'right' }
          );
        }
        addFooter(doc);
        const file_name =
          getLastDayOfMonth(
            incomeStatementRecentTimePeriods[
              incomeStatementRecentTimePeriods.length - 1
            ]
          ) +
          '_reporte_contable_' +
          accountingClientCompany.name +
          '.pdf';
        if (outputOnlyBlob) {
          //message.info('Enviando correo');
          const pdfBlob = doc.output('blob');
          // console.log('Returning PDF Blob:', pdfBlob);
          return pdfBlob;
        } else {
          const trackingData = {
            event: 'exportFinancialStatementsPDF',
            properties: {},
          };
          axios({
            method: 'post',
            url: props.API_domain + 'trackFrontEndEvent',
            auth: {
              username: auth.email,
              password: auth.token,
            },
            data: trackingData,
          })
            .then((response) => {
              console.log('Event tracked:', response);
            })
            .catch((error) => {
              console.error('Error tracking event:', error);
            });
          message.success('PDF generado correctamente');
          console.log('Saving PDF Document:', file_name);
          doc.save(file_name);
        }
      })
      .catch((error) => {
        console.error('Error loading the font or logo image:', error);
      });
  };

  const tabs = [
    {
      key: '1',
      label: `Estado de Resultados`,
      children:
        accountingClientCompany && incomeStatementData ? (
          <div>
            <IncomeStatementTable2
              API_domain={props.API_domain}
              accountingClientCompany={
                accountingClientCompany.accounting_client_id
              }
              incomeStatementData={incomeStatementData}
              incomeStatementRecentTimePeriods={
                incomeStatementRecentTimePeriods
              }
            />
          </div>
        ) : (
          <Table loading={loadingIncomeStatement} />
        ),
    },
    {
      key: '2',
      label: `Balance`,
      children:
        accountingClientCompany && balanceSheetData ? (
          <div>
            <BalanceSheetTable2
              API_domain={props.API_domain}
              accountingClientCompany={
                accountingClientCompany.accounting_client_id
              }
              balanceSheetData={balanceSheetData}
              balanceSheetRecentTimePeriods={balanceSheetRecentTimePeriods}
            />
          </div>
        ) : (
          <Table loading={loadingBalanceSheet} />
        ),
    },
  ];

  const showEmailModal = async () => {
    const get_accounting_client_data_response = await axios.get(
      `${props.API_domain}getDataForAccountingClient/${accountingClientCompany.accounting_client_id}`,
      {
        auth: {
          username: auth.email,
          password: auth.token,
        },
      }
    );

    const { accounting_client_name, emails } =
      get_accounting_client_data_response.data;

    try {
      const pdfBlob = await generatePDF(true);
      const accountingDate = getLastDayOfMonth(
        incomeStatementRecentTimePeriods[
          incomeStatementRecentTimePeriods.length - 1
        ]
      );
      const accountingDateLong = formatDateSpanishLong(accountingDate);

      // Prepare email data
      setEmailData({
        to: emails.join(',') + ',contabilidad@herofacturas.com' || '', // You'll need to set this based on your requirements
        subject: `Reporte Contable - ${accountingClientCompany.name} - ${accountingDateLong}`,
        body: `Hola estimados ${accountingClientCompany.name},

Adjunto pueden ver un reporte de su contabilidad al ${accountingDateLong}.
        
Hero`,
        attachments: [{ name: 'reporte_contable.pdf', content: pdfBlob }],
        metadata: {
          accounting_client_id: accountingClientCompany.accounting_client_id,
          accounting_client_name: accountingClientCompany.name,
          accounting_date: accountingDate,
          accounting_date_long: accountingDateLong,
        },
      });

      // Show the modal for email review
      setIsEmailModalVisible(true);
    } catch (error) {
      console.error('Error preparing email data:', error);
      message.error('Failed to prepare email data. Please try again.');
    }
  };

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        // borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      {loading ? (
        <LoadingDiv>{antIcon}</LoadingDiv>
      ) : hasFinancialStatements && accountingClientCompany ? (
        <>
          <Affix>
            <AffixDiv>
              <Row>
                <Col span={8} style={{ textAlign: 'left' }}>
                  <Row>
                    <h1>{accountingClientCompany.name}</h1>
                  </Row>
                </Col>
                <Col span={16} style={{ textAlign: 'right' }}>
                  <Button
                    type='secondary'
                    onClick={showEmailModal}
                    disabled={
                      !incomeStatementData || !balanceSheetData || email !== ''
                    }
                    style={{ marginRight: 10 }}
                  >
                    <MailOutlined />
                    Mandar <b> Correo</b>
                  </Button>
                  <Button onClick={() => generatePDF()} type='primary'>
                    <DownloadOutlined />
                    PDF
                  </Button>
                </Col>
              </Row>
            </AffixDiv>
          </Affix>

          <div style={{ display: 'flex', width: '100%' }}>
            <Tabs
              defaultActiveKey='1'
              style={{ width: '100%', paddingLeft: '5%' }}
            >
              {tabs.map((tab) => (
                <TabPane tab={tab.label} key={tab.key}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    {tab.children}
                  </div>
                </TabPane>
              ))}
            </Tabs>
          </div>
        </>
      ) : (
        <Row justify='center' align='middle' style={{ minHeight: '100vh' }}>
          <Col xs={22} sm={18} md={14} lg={12}>
            <Typography align='center'>
              <Title level={2}>
                ¿Estás buscando servicios mensuales de contabilidad?
              </Title>
              <Paragraph>
                Aquí podrías ver tu Estado de Resultados (P&L) y tu balance de
                situación (Balance Sheet) en tiempo real.
              </Paragraph>
              <Button
                type='primary'
                size='large'
                onClick={() => {
                  const phoneNumbers = ['50766181519', '50766759599'];
                  const selectedPhone =
                    phoneNumbers[
                      Math.floor(Math.random() * phoneNumbers.length)
                    ];
                  window.open(
                    `https://api.whatsapp.com/send?phone=${selectedPhone}&text=Soy%20usuario%20de%20Hero%20y%20quiero%20contabilidad.`,
                    '_blank'
                  );
                }}
              >
                Conversemos Ahora
              </Button>
            </Typography>
          </Col>
        </Row>
      )}
      <EmailReviewModal
        visible={isEmailModalVisible}
        onCancel={() => setIsEmailModalVisible(false)}
        initialValues={emailData}
        API_domain={props.API_domain}
      />
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { AccountingFinancialStatementsView };
