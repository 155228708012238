import {
  CreditCardOutlined,
  DeleteOutlined,
  LoadingOutlined,
  SwapOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  message,
  Row,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import axios from '../helpers/axiosInterceptor.js';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { CategorySelect } from './CategorySelect.js';
import { FormattedUSD } from './FormattedUSD.js';
import { ImageLoader } from './ImageLoader';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function parseFloatOrNull(number) {
  const parsedFloat = parseFloat(number);
  if (!isNaN(parsedFloat)) {
    return parsedFloat.toFixed(2);
  } else {
    return null;
  }
}

function parseFloatOrCero(number) {
  const parsedFloat = parseFloat(number);
  if (!isNaN(parsedFloat)) {
    return parsedFloat.toFixed(2);
  } else {
    return 0;
  }
}

function NewAndImprovedExpenseForm(props) {
  const auth = useContext(authContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [amountWithoutItbms, setAmountWithoutItbms] = useState(0);
  const [itbms, setItbms] = useState(0);
  const [commandPressed, setCommandPressed] = useState(false);
  const [showProviderFinder, setShowProviderFinder] = useState(
    auth.email === 'noreplyherofacturas@gmail.com' ||
      auth.email === 'jarol@herofacturas.com' ||
      auth.email === 'fernandoavarelaf@gmail.com' ||
      (auth.email === 'alvarososa123@gmail.com' &&
        props.expense.team != 'Hero DEMO')
  );
  const [imageUrl, setImageUrl] = useState(
    props.expense ? props.expense.factura : ''
  );
  const [initialAmount, setInitialAmount] = useState();
  const [inputAmount, setInputAmount] = useState();
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const [reportingCardTransaction, setReportingCardTransaction] =
    useState(false);

  const initialValuesConstructor = () => {
    const expense_date = moment(props.expense.fecha, 'YYYY/MM/DD');
    if (props.expense.processing) {
      setItbms(parseFloatOrNull(props.expense.parsed_itbms));
      setAmountWithoutItbms(
        parseFloatOrNull(props.expense.total) != '0.00'
          ? parseFloatOrNull(
              parseFloat(props.expense.total) -
                parseFloatOrCero(props.expense.parsed_itbms)
            )
          : parseFloatOrNull(
              parseFloat(props.expense.parsed_amount) -
                parseFloatOrCero(props.expense.parsed_itbms)
            )
      );
      return {
        expenseName: props.expense.descripcion,
        date: expense_date.isValid() ? expense_date : '',
        itbms: parseFloatOrNull(props.expense.parsed_itbms),
        amount:
          props.expense.total != '0.00'
            ? parseFloatOrNull(
                parseFloat(props.expense.total) -
                  parseFloatOrCero(props.expense.parsed_itbms)
              )
            : parseFloatOrNull(
                parseFloat(props.expense.parsed_amount) -
                  parseFloatOrCero(props.expense.parsed_itbms)
              ),
        ruc: props.expense.ruc,
        provider: props.expense.provider,
        receipt_number: props.expense.receipt_number,
        category: props.expense.category_id,
      };
    } else {
      setItbms(parseFloatOrNull(props.expense.itbms));
      setAmountWithoutItbms(
        parseFloatOrNull(
          parseFloat(props.expense.total) - parseFloat(props.expense.itbms)
        )
      );
      return {
        expenseName: props.expense.descripcion,
        date: expense_date.isValid() ? expense_date : '',
        itbms: parseFloatOrNull(props.expense.itbms),
        amount: parseFloatOrNull(
          parseFloat(props.expense.total) - parseFloat(props.expense.itbms)
        ),
        ruc: props.expense.ruc,
        provider: props.expense.provider,
        receipt_number: props.expense.receipt_number,
        category: props.expense.category_id,
      };
    }
  };

  // const logFoundCardExpenseMatch = (foundElement) => {
  //   // make axios call to backend POST /logFoundCardExpenseMatch. pass foundElement as data
  //   axios({
  //     method: 'post',
  //     url: props.API_domain + 'logFoundCardExpenseMatch',
  //     data: { foundElement },
  //     auth: {
  //       username: auth.email,
  //       password: auth.token,
  //     },
  //   })
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch(function (error) {
  //       if (error.response) {
  //         // The request was made and the server responded with a status code
  //         if (error.response.status === 403) {
  //           message.error(error.response.data);
  //         }
  //       } else if (error.request) {
  //         // The request was made but no response was received
  //         console.log(error.request);
  //         message.error('No response received from the server.');
  //       } else {
  //         // Something happened in setting up the request that triggered an Error
  //         console.log('Error', error.message);
  //         message.error('An error occurred while setting up the request.');
  //       }
  //       setSubmitting(false);
  //     });
  // };

  const updateAmount = (newAmount) => {
    setInputAmount(newAmount); // Update the local state for InputNumber
    setAmountWithoutItbms(newAmount); // Update the state used in the total calculation
    form.setFieldsValue({ amount: newAmount }); // Update form's amount field to ensure form values are in sync
  };

  useEffect(() => {
    setImageUrl(props.expense.factura);
    const initialValues = initialValuesConstructor();
    form.setFieldsValue(initialValues);
    setInitialAmount(initialValues.amount);
    setInputAmount(initialValues.amount);
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === 'Enter') {
        form.submit();
      }
      if (event.altKey && event.key === 'ArrowRight') {
        props.nextExpense();
      }
      if (event.altKey && event.key === 'ArrowLeft') {
        props.previousExpense();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.expense, form]);

  // const findMatch = (
  //   list,
  //   reference_id,
  //   reference_parsed_amount,
  //   reference_team,
  //   reference_gastante,
  //   reference_date,
  //   reference_card_transaction
  // ) => {
  //   const currentDate = new Date(reference_date);
  //   const targetDate = new Date(currentDate.getTime() - 48 * 60 * 60 * 1000); // 48 hours before the current date

  //   const foundElement = list.find(
  //     (item) =>
  //       item.total ===
  //         (Math.round(reference_parsed_amount * 100) / 100).toFixed(2) &&
  //       new Date(item.fecha) >= targetDate &&
  //       item.gastante === reference_gastante &&
  //       item.team === reference_team &&
  //       item.id != reference_id &&
  //       (item.card_transaction == true || reference_card_transaction == true)
  //   );

  //   foundElement && showProviderFinder
  //     ? (() => {
  //         alert(
  //           `${foundElement.id} ${foundElement.descripcion} ${foundElement.total} NOTIFICAR A ALVARO O FERNANDO`
  //         );
  //         logFoundCardExpenseMatch(foundElement);
  //       })()
  //     : console.log('no match found');
  //   return foundElement ? foundElement : null;
  // };

  useEffect(() => {
    window.addEventListener('keydown', handleKey);
    window.addEventListener('keyup', handleKey);
  }, []);

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      amount: -1 * values.amount - values.itbms, // includes itbms
      date: values.date,
      receipt_number: values.receipt_number,
      imageUrl: imageUrl,
      expenseName: values.expenseName,
      category: values.category,
      id: props.expense.id,
      itbms: -1 * values.itbms,
      ruc: values.ruc,
      provider: values.provider,
    };

    // const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
    //   data.date
    // );
    // const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
    //   data.date
    // );
    // const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
    //   data.date
    // );
    // const formattedDate = `${year}/${month}/${day}`;

    // findMatch(
    //   props.tableExpenses,
    //   props.expense.id,
    //   (-1 * data.amount).toString(),
    //   props.expense.team,
    //   props.expense.gastante,
    //   formattedDate,
    //   props.expense.card_transaction
    // );
    axios({
      method: 'post',
      url: props.API_domain + props.API_endPoint, //updateExpense
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      //withCredentials: true,  // Include cookies with the request
    })
      .then((response) => {
        if (response.data.stitched) {
          message.success('Gasto unido con transacción 💳');
        } else {
          message.success('Gasto actualizado');
        }
        if (commandPressed) {
          props.nextExpense();
          setSubmitting(false);
        } else {
          props.handleOk();
          props.onSubmit();
          setTimeout(() => setSubmitting(false), 2000);
        }
      })
      .catch(function (response) {
        message.error('Error al actualizar el gasto');
        setSubmitting(false);
      });
  };
  const handleKey = (event) => {
    setCommandPressed(event.metaKey || event.altKey);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const findRUC = () => {
    axios({
      method: 'get',
      url:
        props.API_domain +
        'getRucFromProvider?provider=' +
        form.getFieldsValue()['provider'],
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        if (response.data.ruc) {
          message.success('RUC encontrado');
          form.setFieldsValue({
            ruc: response.data.ruc,
          });
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  const onSelect = (value) => {
    form.setFieldsValue({
      category: value,
    });
    return;
  };
  const disabledBoolean =
    props.expense && props.expense.report_id ? true : false;

  const handleFocus = (event) => event.target.select();

  const handleItbmsFocus = () => {
    setItbms(amountWithoutItbms * 0.07);
    form.setFieldsValue({
      itbms: parseFloat(amountWithoutItbms * 0.07).toFixed(2),
    });
    setTimeout(() => {
      inputRef.current.select();
    }, 1);
  };

  const handleProveedorFocus = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[.,!_?'?-]/g, '');
    inputValue = inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    inputValue = inputValue.toUpperCase();

    form.setFieldsValue({
      provider: inputValue,
    });

    setTimeout(() => {
      event.target.select();
    }, 0);
  };

  const handleBlur = (event) => {
    const expression = event.target.value;
    try {
      // Validate and sanitize the expression to prevent malicious code execution
      // This regex allows only numbers, arithmetic operators, and spaces
      if (/^[0-9+\-*/.()\s]*$/.test(expression)) {
        const result = new Function(`return ${expression}`)();
        const formattedResult = parseFloat(result).toFixed(2); // Format the result to two decimal places
        updateAmount(formattedResult); // Update the component state and form
      } else {
        throw new Error('Invalid input');
      }
    } catch (error) {
      console.error('Error evaluating the expression: ', error);
      // Optional: Notify the user of an invalid input or show an error message
    }
  };

  const reportCardTransaction = () => {
    setReportingCardTransaction(true);
    axios({
      method: 'post',
      url: props.API_domain + 'reportCardTransactionError',
      data: { expense_id: props.expense.id },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setReportingCardTransaction(false);
        message.success(
          'Reportado exitosamente. Te contactaremos lo antes posible.'
        );
      })
      .catch(function (response) {
        if (response.response.status == 403) {
          message.error(response.response.data);
        }
      });
  };

  return (
    <>
      <Form
        {...layout}
        name='basic'
        // initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          label='Factura:'
          style={{
            display: '',
          }}
        >
          <Row>
            <Col span={16}>
              <ImageLoader
                onImageSubmit={setImageUrl}
                urlToUpload={props.API_domain + 'uploadImages'}
                disabledBoolean={disabledBoolean}
              />
            </Col>
            <Col span={8}>
              {imageUrl ? (
                <>
                  <Popconfirm
                    title='Estás seguro de que quieres borrar la imagen adjunta?'
                    onConfirm={(event) => {
                      // props.deleteExpense(props.expense.id);
                      setImageUrl('');
                      event.stopPropagation();
                      form.submit();
                    }}
                    onCancel={(event) => event.stopPropagation()}
                    okText='Borrar'
                    cancelText='Cancelar'
                    placement='topRight'
                  >
                    <Tooltip placement='bottom' title='Borrar imagen'>
                      <Button
                        type='secondary'
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                  <Tooltip placement='bottom' title='Descargar archivo'>
                    <Button
                      type='secondary'
                      style={{ marginLeft: '6px' }}
                      onClick={async (event) => {
                        event.stopPropagation();

                        // Fetch the file through your Python backend
                        const response = await fetch(
                          `${
                            props.API_domain
                          }/downloadImage?url=${encodeURIComponent(imageUrl)}`
                        );
                        const blob = await response.blob();

                        // Determine the file extension
                        let fileExtension = 'jpg'; // default to jpg
                        if (blob.type === 'application/pdf') {
                          fileExtension = 'pdf';
                        } else if (blob.type === 'image/png') {
                          fileExtension = 'png';
                        }

                        // Create an object URL and link element
                        const objectUrl = URL.createObjectURL(blob);
                        const a = document.createElement('a');

                        // Configure the link element
                        a.href = objectUrl;
                        a.download = `factura${props.expense.id}.${fileExtension}`; // name your file here
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);

                        // Release the object URL
                        URL.revokeObjectURL(objectUrl);
                      }}
                    >
                      <DownloadOutlined />
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label='Descripción'
          name='expenseName'
          rules={[
            {
              required: true,
              message: 'Por favor escriba el nombre del gasto',
            },
          ]}
        >
          {props.expense.report_id ? (
            <Tooltip placement='topRight' title={'props.expense.descripcion'}>
              <Input
                placeholder='Gasolina'
                disabled={disabledBoolean}
                style={{ color: 'black' }}
              />
            </Tooltip>
          ) : (
            <Input
              placeholder='Gasolina'
              disabled={disabledBoolean}
              style={{ color: 'black' }}
            />
          )}
        </Form.Item>
        <Form.Item
          name='date'
          label='Fecha:'
          rules={[
            {
              required: true,
              message: 'Por favor seleccione una fecha',
            },
          ]}
        >
          <DatePicker
            locale={locale}
            style={{ width: 180 }}
            disabled={disabledBoolean}
          />
        </Form.Item>

        <Form.Item label='Proveedor' name='provider'>
          <Input
            placeholder='Farmacias Arrocha'
            disabled={disabledBoolean}
            style={{ color: 'black' }}
            onFocus={showProviderFinder ? handleProveedorFocus : handleFocus}
          />
        </Form.Item>
        {showProviderFinder ? (
          <Form.Item label='Admin'>
            <Button
              onClick={(event) => {
                findRUC();
                event.stopPropagation();
              }}
            >
              Buscar RUC
            </Button>
            {/* <Button
              onClick={(event) => {
                readQR();
                event.stopPropagation();
              }}
              style={{ marginLeft: '10px' }}
            >
              Leer QR
            </Button> */}
          </Form.Item>
        ) : (
          ''
        )}
        <Form.Item label='RUC' name='ruc'>
          <Input
            placeholder='12345-123-45678'
            disabled={disabledBoolean}
            style={{ color: 'black' }}
            onFocus={handleFocus}
          />
        </Form.Item>
        <Form.Item label='Número de Factura' name='receipt_number'>
          <Input
            placeholder='012345'
            disabled={disabledBoolean}
            style={{ color: 'black' }}
            onFocus={handleFocus}
          />
        </Form.Item>
        <Form.Item label='Categoría' name='category'>
          <CategorySelect
            API_domain={props.API_domain}
            onChange={onSelect}
            defaultCategory={props.expense.category_id}
            disabledBoolean={disabledBoolean}
            team={props.expense.team}
            expenseId={props.expense.id}
          />
        </Form.Item>
        <Form.Item
          label='Monto sin impuestos'
          name='amount'
          rules={[
            {
              required: true,
              message: 'Por favor escriba el monto (número) sin impuestos',
            },
          ]}
        >
          <Space>
            <InputNumber
              style={{ width: 80, color: 'black' }}
              placeholder='10.00'
              step='0.01'
              value={inputAmount}
              onChange={updateAmount}
              disabled={disabledBoolean}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <Button
              type='default'
              onClick={() =>
                updateAmount(parseFloatOrNull(initialAmount * 1000))
              }
              tabIndex={-1}
              style={{
                backgroundColor: 'white',
                color: 'rgba(0, 160, 0, 1)', // Corrected: RGBA value should be a string
                borderColor: 'green',
                border: '0.5px solid rgba(0, 160, 0, 1)', // Added to ensure border color applies
                fontSize: 10.5,
                fontWeight: 300,
                padding: '5px',
              }}
            >
              {<FormattedUSD total={parseFloatOrNull(initialAmount * 1000)} />}
            </Button>
            <Button
              type='default'
              onClick={() =>
                updateAmount(parseFloatOrNull(initialAmount * 100))
              }
              tabIndex={-1}
              style={{
                backgroundColor: 'white',
                color: 'rgba(0, 160, 0, 1)', // Corrected: RGBA value should be a string
                borderColor: 'green',
                border: '0.5px solid rgba(0, 160, 0, 1)', // Added to ensure border color applies
                fontSize: 10.5,
                fontWeight: 300,
                padding: '5px',
              }}
            >
              {<FormattedUSD total={parseFloatOrNull(initialAmount * 100)} />}
            </Button>
            <Button
              type='default'
              onClick={() =>
                updateAmount(parseFloatOrNull(initialAmount * 0.01))
              }
              tabIndex={-1}
              style={{
                backgroundColor: 'white',
                color: 'rgba(0, 160, 0, 1)', // Corrected: RGBA value should be a string
                borderColor: 'green',
                border: '0.5px solid rgba(0, 160, 0, 1)', // Added to ensure border color applies
                fontSize: 10.5,
                fontWeight: 300,
                padding: '5px',
              }}
            >
              {<FormattedUSD total={parseFloatOrNull(initialAmount * 0.01)} />}
            </Button>
            <Button
              type='default'
              tabIndex={-1}
              onClick={() => updateAmount(parseFloatOrNull(initialAmount))}
              style={{
                backgroundColor: 'white',
                color: 'rgba(0, 160, 0, 1)', // Corrected: RGBA value should be a string
                borderColor: 'green',
                border: '0.5px dashed rgba(0, 160, 0, 1)', // Added to ensure border color applies
                fontSize: 10.5,
                fontWeight: 300,
                padding: '5px',
              }}
            >
              {<FormattedUSD total={initialAmount} />}
            </Button>
          </Space>
        </Form.Item>
        <Form.Item
          label='Impuestos'
          name='itbms'
          onFocus={handleItbmsFocus}
          rules={[
            {
              required: true,
              message: 'Por favor escriba la cantidad (número) del impuesto',
            },
          ]}
        >
          <InputNumber
            ref={inputRef}
            style={{ width: 80, color: 'black' }}
            placeholder='0.70'
            step='0.01'
            onChange={(value) => setItbms(value)}
            disabled={disabledBoolean}
          />
        </Form.Item>
        <Form.Item label='Total'>
          <FormattedUSD
            total={parseFloatOrNull(+amountWithoutItbms + +itbms)}
          />
          {props.expense.card_atm_withdrawal ? (
            <span style={{ marginLeft: 6 }}>🏧</span>
          ) : props.expense.card_transaction ? (
            <span style={{ marginLeft: 6 }}>💳</span>
          ) : (
            ''
          )}
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Row>
            <Col
              span={
                // props.expense.report_id || props.expense.card_transaction
                //   ? 24
                //   : 18
                18
              }
            >
              {props.expense && props.expense.report_id ? (
                <Tooltip
                  placement='topRight'
                  title='No puedes editar un gasto reportado'
                >
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled
                    block
                    style={{ fontWeight: 500 }}
                  >
                    Actualizar gasto
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  placement='top'
                  title={
                    <div>
                      <span
                        style={{
                          display: 'inline-block',
                          padding: '2px 5px',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '3px',
                          fontWeight: 'bold',
                          fontFamily: 'Arial, sans-serif',
                          fontSize: '0.9em',
                          marginRight: '5px',
                          color: 'black',
                        }}
                      >
                        ⌘ / control
                      </span>
                      <span>
                        + click para actualizar y continuar al próximo gasto
                      </span>
                    </div>
                  }
                >
                  <Button
                    type='primary'
                    htmlType='submit'
                    block
                    style={{ fontWeight: 500 }}
                  >
                    {isSubmitting ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <>Actualizar gasto</>
                    )}
                  </Button>
                </Tooltip>
              )}
            </Col>
            <Col span={6}>
              {/* {props.expense.report_id || props.expense.card_transaction ? ( */}
              {props.expense.card_transaction ? (
                <>
                  <Tooltip placement='topRight' title='Reportar problema 💳'>
                    <Button
                      style={{ margin: '0 4px', width: '100%' }}
                      onClick={reportCardTransaction}
                    >
                      {reportingCardTransaction ? (
                        <Spin indicator={<LoadingOutlined spin />} />
                      ) : (
                        <>🙋</>
                      )}
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Popconfirm
                    title='Estás seguro de que quieres borrar este gasto?'
                    onConfirm={(event) => {
                      props.deleteExpense(props.expense.id);
                      event.stopPropagation();
                    }}
                    onCancel={(event) => event.stopPropagation()}
                    okText='Borrar'
                    cancelText='Cancelar'
                    placement='topRight'
                  >
                    <Tooltip placement='right' title='Borrar gasto'>
                      <Button
                        danger
                        style={{ margin: '0 4px', width: '100%' }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </>
              )}
            </Col>
          </Row>
        </Form.Item>
        {showProviderFinder && (
          <Row style={{ color: 'grey' }}>
            Subida a Hero: {props.expense.created_at}
          </Row>
        )}
      </Form>
    </>
  );
}

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { NewAndImprovedExpenseForm };
