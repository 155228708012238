import { Affix, Col, Layout, message, Row, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { ConfigNewTeamModal } from './ConfigNewTeamModal.js';
import { Refresh } from './Refresh.js';
import { TeamSelect } from './TeamSelect.js';
import { ConfigCompanySettings } from './Config/ConfigCompanySettings.js';
import { ConfigUserSettings } from './ConfigUserSettings.js';
import { ConfigTeamSettings } from './ConfigTeamSettings.js';
import { ConfigCreateTeamFromExisting } from './ConfigCreateTeamFromExisting.js';

const { Content } = Layout;
const { TabPane } = Tabs;

function ConfigView(props) {
  const auth = useContext(authContext);
  const isOwner =
    auth.email == props.ownerEmail ||
    auth.email == 'noreplyherofacturas@gmail.com' ||
    auth.email == 'jarol@herofacturas.com';
  const [teams, setTeams] = useState();
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [showSubscriptionSettings, setShowSubscriptionSettings] =
    useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [isUserSettingsActive, setIsUserSettingsActive] = useState(false);
  const [accountingClientCompany, setAccountingClientCompany] = useState({});
  const [defaultTab, setDefaultTab] = useState('3');

  useEffect(() => {
    const getUserAccountingClientCompanyInfo = async () => {
      try {
        const response = await axios.post(
          props.API_domain + 'getUserAccountingClientCompanyInfo',
          {},
          {
            auth: {
              username: auth.email,
              password: auth.token,
            },
          }
        );
        const {
          accounting_client_id,
          accounting_client_company_id,
          name,
          ruc,
          logo_image_url,
          wallet_enabled,
        } = response.data;
        if (accounting_client_id) {
          setAccountingClientCompany({
            accounting_client_id,
            accounting_client_company_id,
            name,
            ruc,
            logo_image_url,
            wallet_enabled,
          });
        } else {
          setDefaultTab('1');
        }
      } catch (error) {
        message.error('Error al obtener la información de la empresa contable');
        console.error('Error fetching accounting client:', error);
      }
    };

    getUserAccountingClientCompanyInfo();
  }, [auth, props.API_domain]);

  const fetchTeams = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getTeamsWithOwners',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
      },
    })
      .then((response) => {
        setTeams(response.data);
        setLoaded(true);
      })
      .catch((response) => {
        setError(response);
        setLoaded(true);
      });
  };

  const fetchSubscriptionSettings = () => {
    axios({
      method: 'get',
      url: props.API_domain + 'getSubscriptionSettings',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setShowSubscriptionSettings(response.data.subscriptionSettings);
        setSubscriptionId(response.data.subscriptionId);
      })
      .catch((response) => {
        console.log(response);
        // setError(response);
      });
  };

  useEffect(() => {
    setLoaded(false);
    fetchTeams();
    fetchSubscriptionSettings();
  }, [props.team, submittedToggle]);

  // Inside the ConfigUserSettings component, call this function when clicked
  const handleUserSettingsClick = () => {
    setIsUserSettingsActive(true);
  };

  // Reset this state when you leave ConfigUserSettings or click elsewhere
  const handleUserSettingsExit = () => {
    setIsUserSettingsActive(false);
  };

  const onCreatingNewTeam = () => {
    setSubmittedToggle(!submittedToggle);
    props.onTeamSelect('all'); // this is to solve bug: when a user only has 1 team and creates a second team, user is unaware that it was created
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <Content
        style={{
          margin: '0 0 0',
          overflow: 'initial',
          // borderLeft: 'solid rgb(235,235,235) 1px',
          borderRight: 'solid rgb(235,235,235) 1px',
          borderTop: 'solid rgb(235,235,235) 1px',
        }}
      >
        <Affix>
          <AffixDiv>
            <Row>
              {!isUserSettingsActive && (
                <>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Refresh spin={!isLoaded} />
                    <TeamSelect
                      API_domain={props.API_domain}
                      onTeamSelect={props.onTeamSelect}
                      team={props.team}
                    />
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}></Col>
                </>
              )}
            </Row>
          </AffixDiv>
        </Affix>
        <LoadingDiv>{antIcon}</LoadingDiv>
      </Content>
    );
  } else {
    let fullCard = false;
    if (teams.length < 2) {
      fullCard = true;
    }
    return (
      <>
        <Affix>
          <AffixDiv>
            <Row style={{ height: '20px' }}>
              {!isUserSettingsActive && (
                <>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Refresh onClick={onCreatingNewTeam} spin={!isLoaded} />
                    <TeamSelect
                      API_domain={props.API_domain}
                      onTeamSelect={props.onTeamSelect}
                      team={props.team}
                    />
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <ConfigNewTeamModal
                      API_domain={props.API_domain}
                      onSubmit={onCreatingNewTeam}
                      teams={teams}
                      isOwner={isOwner}
                      fullCard={fullCard}
                    />
                    {(auth.email == 'vsosa1314@derpet.com.pa' ||
                      auth.email == 'noreplyherofacturas@gmail.com') && (
                      <ConfigCreateTeamFromExisting
                        API_domain={props.API_domain}
                      />
                    )}
                  </Col>
                </>
              )}
            </Row>
          </AffixDiv>
        </Affix>

        <Tabs
          defaultActiveKey={defaultTab}
          style={{ marginLeft: 35 }}
          onChange={(activeKey) => {
            if (activeKey === '2') {
              handleUserSettingsClick(); // Set isUserSettingsActive to true
            } else if (activeKey === '1') {
              handleUserSettingsExit(); // Set isUserSettingsActive to false
            }
          }}
        >
          {auth.showAccountingFeatures && (
            <TabPane
              tab={
                <div
                  style={{
                    textAlign: 'left',
                    paddingTop: 16,
                    paddingBottom: 8,
                    fontSize: 26,
                    fontWeight: 'bold',
                  }}
                >
                  Compañía
                </div>
              }
              key='3'
            >
              <ConfigCompanySettings
                API_domain={props.API_domain}
                team={props.team}
                subscriptionId={subscriptionId}
                isOwner={isOwner}
                accountingClientCompany={accountingClientCompany}
              />
            </TabPane>
          )}
          <TabPane
            tab={
              <div
                style={{
                  textAlign: 'left',
                  paddingTop: 16,
                  paddingBottom: 8,
                  fontSize: 26,
                  fontWeight: 'bold',
                }}
              >
                Equipos
              </div>
            }
            key='1'
          >
            <ConfigTeamSettings
              API_domain={props.API_domain}
              teams={teams}
              isOwner={isOwner}
              fullCard={fullCard}
            />
          </TabPane>
          {showSubscriptionSettings && (
            <TabPane
              tab={
                <div
                  style={{
                    textAlign: 'left',
                    paddingTop: 16,
                    paddingBottom: 8,
                    fontSize: 26,
                    fontWeight: 'bold',
                  }}
                >
                  Usuarios
                </div>
              }
              key='2'
            >
              <ConfigUserSettings
                API_domain={props.API_domain}
                team={props.team}
                subscriptionId={subscriptionId}
                isOwner={isOwner}
              />
            </TabPane>
          )}
        </Tabs>
      </>
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;
const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

export { ConfigView };
