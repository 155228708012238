import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { Form, Button, message, Table, Divider, Tooltip, Tag } from 'antd';
import {
  CreditCardOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import styled from 'styled-components/macro';
import { formatPhoneNumber } from '../../utils.js';
import CreateAccountingClientConfigSite from './CreateAccountingClientConfigSite';
import CompanySection from './CompanySection';
import DGIIntegration from './DGIIntegration';
import MunicipioIntegration from './MunicipioIntegration';
import BGIntegration from './BGIntegration';
import CSSIntegration from './CSSIntegration';
import InvuIntegration from './InvuIntegration';
import { ConfigEditAccountingUser } from './ConfigEditAccountingUser.js';
import { ConfigCompanyMoneySettings } from './ConfigCompanyMoneySettings.js';

const ConfigCompanySettings = (props) => {
  const auth = useContext(authContext);
  const [accountingClientCredentials, setAccountingClientCredentials] =
    useState({});
  // const [subscriptionUsers, setSubscriptionUsers] = useState([]);
  // const [selectedMember, setSelectedMember] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchCompanyCredentials();
    // fetchSubscriptionUsers();
  }, [props.accountingClientCompany, refresh]);

  const fetchCompanyCredentials = () => {
    if (!props.accountingClientCompany.accounting_client_id) return;
    axios({
      method: 'post',
      url: props.API_domain + 'getAccountingClientConfigs',
      data: {
        accounting_client_id:
          props.accountingClientCompany.accounting_client_id,
      },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setAccountingClientCredentials(response.data);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error('An error occurred while fetching company info', error);
        setIsLoaded(true);
      });
  };

  const getStripeSubscriptionURL = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'stripeGetSubscriptionURL',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        subscriptionId: props.subscriptionId,
      },
    })
      .then((response) => {
        if (response.data.sessionURL) {
          window.open(response.data.sessionURL, '_blank');
        }
      })
      .catch((error) => {
        console.log('Error fetching Stripe Subscription URL:', error);
      });
  };

  // const fetchSubscriptionUsers = () => {
  //   axios({
  //     method: 'get',
  //     url: props.API_domain + 'getSubscriptionUsers',
  //     auth: {
  //       username: auth.email,
  //       password: auth.token,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.data.subscriptionUsers) {
  //         setSubscriptionUsers(response.data.subscriptionUsers);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching subscription users:', error);
  //     });
  // };

  // Function to update specific credentials
  const updateAccountingClientConfig = async (updatedCredentials) => {
    try {
      const { id, accounting_client_id, site, login_info } = updatedCredentials;

      // Prepare the data in the format expected by the backend
      const requestData = {
        id: id,
        accounting_client_id: accounting_client_id,
        site: site,
        login_info: login_info,
      };

      const response = await axios.post(
        props.API_domain + 'updateAccountingClientConfig',
        requestData,
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );

      // Update the local state
      setAccountingClientCredentials((prevCredentials) =>
        prevCredentials.map((credential) =>
          credential.id === id
            ? { ...credential, ...updatedCredentials }
            : credential
        )
      );

      return response.data;
    } catch (error) {
      console.error(`Error updating ${updatedCredentials.site} config:`, error);
      throw error;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success('Copiado');
  };

  const columns = [
    {
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Celular',
      dataIndex: 'phone',
      key: 'phone',
      width: 160,
      render: (phone) =>
        phone.includes('phone') ? '' : formatPhoneNumber(phone),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Equipos',
      dataIndex: 'finca',
      key: 'finca',
      width: 160,
      render: (finca) =>
        finca.length > 0 ? (
          <Tooltip
            title={finca.map((finca, index) => (
              <div key={index}>
                {index + 1}. {finca}
              </div>
            ))}
            placement='left'
          >
            <Tag color='orange'>{finca.length}</Tag>
          </Tooltip>
        ) : (
          <Tooltip
            title='Desde la pestaña de Equipos podrás agregar a este usuario a un equipo'
            placement='left'
          >
            <span style={{ marginRight: 8 }}>{finca.length}</span>
            <Tag color='purple'>Pendiente</Tag>
          </Tooltip>
        ),
    },
    {
      title: 'Admin',
      dataIndex: 'stripe_subscription_id',
      key: 'admin',
      width: 80,
      render: (stripe_subscription_id) =>
        stripe_subscription_id ? (
          <Tooltip
            title={
              <>
                Puede<div> - Habilitar/Deshabilitar a otros Admins</div>
                <div>- Ver y editar todos los usuarios de la compañía</div>
              </>
            }
            placement='left'
          >
            <Tag color='blue'>Admin</Tag>
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Button type='text' icon={<EditOutlined style={{ color: 'grey' }} />} />
      ),
    },
  ];

  if (!isLoaded) {
    return <LoadingDiv>{antLoadingIcon}</LoadingDiv>;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div style={{ width: '100%', maxWidth: '1000px' }}>
        <Form
          layout='vertical'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <h3 style={{ marginTop: '16px', textAlign: 'left' }}>Suscripción</h3>
          <div style={{ textAlign: 'left', marginLeft: 96 }}>
            <>
              {props.subscriptionId &&
              props.subscriptionId.includes('plink') ? (
                <Button
                  onClick={() => {
                    window.open(props.subscriptionId, '_blank');
                  }}
                  type='primary'
                  style={{
                    width: 200,
                  }}
                >
                  Pagar suscripción
                  <CreditCardOutlined />
                </Button>
              ) : (
                <Button
                  onClick={getStripeSubscriptionURL}
                  style={{
                    width: 200,
                    backgroundColor: 'hsl(0, 0%, 95%)',
                    borderColor: 'hsl(0, 0%, 95%)',
                  }}
                >
                  Manejar Suscripción <CreditCardOutlined />
                </Button>
              )}
            </>
          </div>
          <Divider />
          <CompanySection
            API_domain={props.API_domain}
            copyToClipboard={copyToClipboard}
            accountingClientCompany={props.accountingClientCompany}
          />
          <Divider />
          <div style={{ textAlign: 'left' }}>
            <h3>Dinero</h3>
          </div>
          <ConfigCompanyMoneySettings
            API_domain={props.API_domain}
            copyToClipboard={copyToClipboard}
            accountingClientCompany={props.accountingClientCompany}
          />
          <Divider />
          {/* <h2 style={{ marginTop: '40px', textAlign: 'left' }}>Usuarios</h2>
          <Table
            dataSource={subscriptionUsers}
            columns={columns}
            style={{ paddingTop: 10, maxWidth: 1200, marginLeft: 8 }}
            pagination={false}
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectedMember(record);
                  console.log('record:', record);
                },
              };
            }}
          /> */}
          {accountingClientCredentials.find((item) => item.site === 'dgi') ? (
            <>
              <DGIIntegration
                dgiCredentials={accountingClientCredentials.find(
                  (item) => item.site === 'dgi'
                )} //only the item that has site dgi
                copyToClipboard={copyToClipboard}
                updateAccountingClientConfig={updateAccountingClientConfig} // Pass update function
              />
              <Divider />
            </>
          ) : (
            <CreateAccountingClientConfigSite
              API_domain={props.API_domain}
              accountingClientId={
                props.accountingClientCompany.accounting_client_id
              }
              site='dgi'
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}
          {accountingClientCredentials.find(
            (item) => item.site === 'municipio'
          ) ? (
            <>
              <MunicipioIntegration
                municipioCredentials={accountingClientCredentials.find(
                  (item) => item.site === 'municipio'
                )} //only the item that has site dgi
                accountingClientCredentials={accountingClientCredentials}
                copyToClipboard={copyToClipboard}
                updateAccountingClientConfig={updateAccountingClientConfig} // Pass update function
              />
              <Divider />
            </>
          ) : (
            <CreateAccountingClientConfigSite
              API_domain={props.API_domain}
              accountingClientId={
                props.accountingClientCompany.accounting_client_id
              }
              site='municipio'
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}
          {auth.adminEmail ? (
            accountingClientCredentials.find((item) => item.site === 'bg') ? (
              <>
                <BGIntegration
                  bgCredentials={accountingClientCredentials.find(
                    (item) => item.site === 'bg'
                  )} // only the item that has site 'bg'
                  accountingClientCredentials={accountingClientCredentials}
                  copyToClipboard={copyToClipboard}
                  updateAccountingClientConfig={updateAccountingClientConfig} // Pass update function
                />
                <Divider />
              </>
            ) : (
              <CreateAccountingClientConfigSite
                API_domain={props.API_domain}
                accountingClientId={
                  props.accountingClientCompany.accounting_client_id
                }
                site='bg'
                refresh={refresh}
                setRefresh={setRefresh}
              />
            )
          ) : (
            <></>
          )}

          {accountingClientCredentials.find((item) => item.site === 'css') ? (
            <>
              <CSSIntegration
                cssCredentials={accountingClientCredentials.find(
                  (item) => item.site === 'css'
                )} //only the item that has site dgi
                accountingClientCredentials={accountingClientCredentials}
                copyToClipboard={copyToClipboard}
                updateAccountingClientConfig={updateAccountingClientConfig} // Pass update function
              />
              <Divider />
            </>
          ) : (
            <CreateAccountingClientConfigSite
              API_domain={props.API_domain}
              accountingClientId={
                props.accountingClientCompany.accounting_client_id
              }
              site='css'
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}
          {
            <InvuIntegration
              invuCredentials={accountingClientCredentials.filter(
                (item) => item.site === 'invu'
              )}
              copyToClipboard={copyToClipboard}
              accountingClientId={
                props.accountingClientCompany.accounting_client_id
              }
              API_Domain={props.API_domain}
            />
          }
        </Form>
      </div>
      {/* {selectedMember && (
        <ConfigEditAccountingUser
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          API_domain={props.API_domain}
        />
      )} */}
    </div>
  );
};

const antLoadingIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { ConfigCompanySettings };
